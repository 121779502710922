import React from "react";
import dayjs from "dayjs";

import EmissionsTable from "../../modules/tables/EmissionsTable";
import EmissionsDetailsAccordionBlock from "../../layouts/EmissionsDetailsAccordionBlock";
import EmissionsCsvDownload from "../../atoms/buttons/EmissionsCsvDownload";

const EmissionsDetailsTableAccordion = ({
  type,
  headers = [],
  setSelectedTransaction,
  closeSelectedObjectSlider,
  emissions,
  isExpanded,
  onChange,
  name,
  title = "Table of Emissions",
  subtitle,
  action,
  avatar,
  onSelectRow,
  selectedRows = [],
  noSort,
}) => {
  return (
    <EmissionsDetailsAccordionBlock
      avatar={avatar}
      expanded={isExpanded}
      onChange={onChange}
      type={type}
      blockAction={
        action || (
          <EmissionsCsvDownload
            emissions={emissions}
            fileName={`${dayjs().format("DDMMYYYY")}-${name}-emissions`}
          />
        )
      }
      title={title}
      subtitle={subtitle}
      details={
        <EmissionsTable
          headers={headers}
          setSelectedTransaction={setSelectedTransaction}
          closeSelectedObjectSlider={closeSelectedObjectSlider}
          onSelectRow={onSelectRow}
          selectedRows={selectedRows}
          emissions={emissions}
          noSort={noSort}
        />
      }
    />
  );
};
export default EmissionsDetailsTableAccordion;
