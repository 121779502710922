import React, { useState } from "react";
// import dayjs from "dayjs";

import {
  Box,
  Grid,
  Typography,
  Paper,
  IconButton,
  useTheme,
} from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessageBot } from "@fortawesome/pro-solid-svg-icons";

// import { DefaultPaper } from "@aclymatepackages/atoms";
import { formatDecimal } from "@aclymatepackages/formatters";

import EmissionsCsvDownload from "../atoms/buttons/EmissionsCsvDownload";
import DetailsActionAlert from "../atoms/notifications/DetailsActionAlert";
import SlideLayout from "../layouts/SlideLayout";
import SearchActionInput from "../atoms/inputs/SearchActionInput";
import EmissionsTable from "./tables/EmissionsTable";

// import { useCachedDisplayData } from "../../helpers/firebase";
import { fetchOurApi } from "../../helpers/utils/apiCalls";
import useEmissionsContext from "../../helpers/contexts/emissions";
import { analyticsTrack } from "../../helpers/analytics";

const ThumbFeedbackButton = ({
  ThumbComponent,
  value,
  selectedThumb,
  setSelectedThumb,
}) => (
  <Grid item>
    <IconButton
      disabled={!!selectedThumb}
      onClick={() => {
        setSelectedThumb(value);
        return analyticsTrack("AI Thumb Feedback", { value });
      }}
    >
      <ThumbComponent style={{ color: "white" }} />
    </IconButton>
  </Grid>
);

const ThumbFeedbackButtons = () => {
  const [selectedThumb, setSelectedThumb] = useState("");

  const thumbsArray = [
    {
      value: "up",
      ThumbComponent: selectedThumb === "up" ? ThumbUpIcon : ThumbUpOffAltIcon,
    },
    {
      value: "down",
      ThumbComponent:
        selectedThumb === "down" ? ThumbDownIcon : ThumbDownOffAltIcon,
    },
  ];

  return (
    <Grid container wrap="nowrap">
      {thumbsArray.map((thumb, idx) => (
        <ThumbFeedbackButton
          key={`thumb-feedback-button-${idx}`}
          {...thumb}
          selectedThumb={selectedThumb}
          setSelectedThumb={setSelectedThumb}
        />
      ))}
    </Grid>
  );
};

const FeedbackWarning = () => {
  const { palette } = useTheme();
  return (
    <DetailsActionAlert
      color={palette.warning.main}
      text="This is an experimental feature. We'd love for you to let us know how we did?"
      action={<ThumbFeedbackButtons />}
    />
  );
};

//We're commenting this out for now because we're want customer/marketing feedback on its design
// const QuestionTips = () => {
//   const [offices] = useCachedDisplayData("offices");
//   const [employees] = useCachedDisplayData("employees");
//   const [vendors] = useCachedDisplayData("vendors");
//   const [events] = useCachedDisplayData("events");

//   const sampleQuestions = [
//     "How much carbon did my company emit last year?",
//     `How many total kwH of electricity did my ${
//       offices[0]?.name
//     } office use in ${dayjs().year() - 1}.`,
//     `Show me all the transactions for ${employees[0]?.name} in Q3 of this year.`,
//     `List all of the emissions from ${
//       vendors[0]?.name || "My Favorite Vendor"
//     } last month?`,
//     `How many therms of natural gas did we use in Q2 of ${dayjs().year()}.`,
//     `How many tons of carbon did we emit at ${
//       events[0]?.name || "My Favorite Event"
//     } last year?`,
//   ];

//   return (
//     <DefaultPaper>
//       <Grid container direction="column" spacing={2}>
//         <Grid item>
//           <Typography variant="body1">
//             This AI feature is designed to answer questions about your emissions
//             during a specific period of time. It is NOT a general chatbot to
//             answer any questions about sustainability. When asking questions,
//             it's important to be as as specific as possible in what you're
//             asking for. Here are some sample questions you can ask:
//           </Typography>
//         </Grid>
//         {sampleQuestions.map((question, idx) => (
//           <Grid item key={`sample-question-${idx}`}>
//             <Typography variant="body2">{question}</Typography>
//           </Grid>
//         ))}
//       </Grid>
//     </DefaultPaper>
//   );
// };

const AnswerDisplay = ({ answer }) => {
  if (Array.isArray(answer)) {
    return (
      <Paper>
        <Box p={2}>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">Your Emissions</Typography>
            </Grid>
            <Grid item>
              <EmissionsCsvDownload emissions={answer} />
            </Grid>
          </Grid>
        </Box>
        <EmissionsTable emissions={answer} />
      </Paper>
    );
  }

  return (
    <Typography variant="h3" align="center" display="block">
      {`${answer}`}
    </Typography>
  );
};

const AiQuestionsInterface = ({
  showAiQuestionsInterface,
  setShowAiQuestionsInterface,
}) => {
  const { palette } = useTheme();

  const { allEmissions } = useEmissionsContext();

  const [question, setQuestion] = useState("");
  const [answerLoading, setAnswerLoading] = useState(false);
  const [answer, setAnswer] = useState(null);
  const [error, setError] = useState(false);

  const onAskQuestion = async () => {
    setAnswerLoading(true);
    setAnswer(null);
    setError(false);

    const executeFunctionFromCode = ({ functionCode }, data) => {
      const sanitizedFunctionCode = functionCode
        .trim()
        .replace(/^\s*`+|`+\s*$/g, "");

      const scope = {};
      //eslint-disable-next-line
      eval(`scope.responseFunction = ${sanitizedFunctionCode}`);
      return scope.responseFunction(data);
    };

    const fetchTemplateAnswer = (answer) => {
      try {
        return fetchOurApi({
          path: "/open-ai/emissions-report-answer-template",
          data: { question },
          method: "POST",
          callback: (response) => {
            const formattedAnswer = executeFunctionFromCode(
              response,
              formatDecimal(answer)
            );
            return setAnswer(formattedAnswer);
          },
        });
      } catch (error) {
        analyticsTrack("AI Question Error", { error, question });
        return setError(true);
      }
    };

    const emissionsReportQuestionCallback = async (response) => {
      try {
        const answer = executeFunctionFromCode(response, allEmissions);
        if (!Array.isArray(answer)) {
          return await fetchTemplateAnswer(answer);
        }

        return setAnswer(answer);
      } catch (error) {
        analyticsTrack("AI Question Error", { error, question });
        return setError(true);
      }
    };

    await fetchOurApi({
      path: "/open-ai/emissions-report-question",
      data: { question },
      method: "POST",
      callback: emissionsReportQuestionCallback,
    });
    return setAnswerLoading(false);
  };

  return (
    <SlideLayout
      setIsSlideOpen={setShowAiQuestionsInterface}
      isSlideOpen={showAiQuestionsInterface}
      header={
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <FontAwesomeIcon
              icon={faMessageBot}
              size="2x"
              style={{ color: palette.primary.main }}
            />
          </Grid>
          <Grid item>
            <Typography variant="h4">AI Powered Emissions Insights</Typography>
          </Grid>
        </Grid>
      }
      // leftContent={<QuestionTips />}
      content={
        <Grid container direction="container" spacing={2}>
          <Grid item xs={12}>
            <SearchActionInput
              label="Ask us a question about your emissions?"
              value={question}
              setValue={setQuestion}
              searchLoading={answerLoading}
              searchFunction={onAskQuestion}
              multiline
              rows={4}
            />
          </Grid>
          {answer !== null && (
            <>
              <Grid item xs={12}>
                <FeedbackWarning />
              </Grid>
              <Grid item xs={12}>
                <AnswerDisplay answer={answer} />
              </Grid>
            </>
          )}
          {error && (
            <Grid item xs={12}>
              <Typography variant="h3" color="error" align="center">
                There was an error processing your question. Please adjust your
                question and try again.
              </Typography>
            </Grid>
          )}
        </Grid>
      }
    />
  );
};
export default AiQuestionsInterface;
