import React, { useEffect, useState } from "react";

import {
  Grid,
  Typography,
  CircularProgress,
  ButtonBase,
  Paper,
  Slider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";

import { DefaultPaper } from "@aclymatepackages/atoms";
import { getYearlyTonsCo2eValues } from "@aclymatepackages/calcs/myAclymate";

import ReportContentCard from "./ReportCardContent";
import SubscriptionDetailsSlider from "./SubscriptionDetailsSlider";

import ProjectSummaryDisplay from "../modules/projects/ProjectSummaryDisplay";

import { fetchOurApi } from "../../helpers/utils/apiCalls";

const useCNaughtPortfolios = () => {
  const [cNaughtPortfolios, setCNaughtPortfolios] = useState([]);
  const [cNaughtPortfoliosLoading, setCNaughtPortfoliosLoading] =
    useState(true);

  useEffect(() => {
    const fetchCNaughtPortfolios = async () => {
      try {
        const response = await fetchOurApi({
          path: "/integrations/cNaught/get-portfolios",
          method: "POST",
          data: {},
          callback: (res) => res,
        });
        const { success, portfolios } = response;

        if (!success) {
          return setCNaughtPortfolios([]);
        }

        const formattedPortfolios = portfolios.map((portfolio) => ({
          ...portfolio,
          stripePriceId: "price_1QO5XXCyH5vE63VfgD4OUlna",
        }));

        setCNaughtPortfolios(formattedPortfolios);
      } catch (error) {
        console.error("Failed to fetch cNaught portfolios:", error);
      } finally {
        setCNaughtPortfoliosLoading(false);
      }
    };

    if (cNaughtPortfoliosLoading) {
      fetchCNaughtPortfolios();
    }
  }, [cNaughtPortfoliosLoading]);

  return [cNaughtPortfolios, cNaughtPortfoliosLoading];
};

const OffsetBucket = ({ portfolio, setSelectedOffsetBucket }) => {
  const [hover, setHover] = useState(false);

  const { name, primary_image_url } = portfolio;

  return (
    <ButtonBase
      onClick={() => setSelectedOffsetBucket(portfolio)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{ width: "100%", height: "100%", textAlign: "left" }}
    >
      <Paper
        elevation={hover ? 4 : 1}
        style={{ width: "100%", height: "100%", overflow: "hidden" }}
      >
        <ProjectSummaryDisplay
          project={{
            name: name,
            images: [
              primary_image_url ||
                "/images/project-categories/conservation1.jpg",
            ],
          }}
          noIcons
        />
      </Paper>
    </ButtonBase>
  );
};

const OffsetBucketsBox = ({
  isDesktop,
  emissionsData,
  SubscriptionPurchaseFlow,
  numPeople,
}) => {
  const [cNaughtPortfolios, cNaughtPortfoliosLoading] = useCNaughtPortfolios();

  const [selectedOffsetBucket, setSelectedOffsetBucket] = useState(null);

  const { yearlyTotalTonsCo2e } = getYearlyTonsCo2eValues(emissionsData);
  const OFFSET_SUBSCRIPTION_COST_PER_TON = 30;
  const monthlySubscriptionCost = Math.ceil(
    (yearlyTotalTonsCo2e / 12) * OFFSET_SUBSCRIPTION_COST_PER_TON
  );

  const [selectedPercentage, setSelectedPercentage] = useState(100);
  const [customAmount, setCustomAmount] = useState(null);
  const [isCustomDialogOpen, setIsCustomDialogOpen] = useState(false);

  const handleSliderChange = (event, value) => {
    setSelectedPercentage(value);
    setCustomAmount(null);
  };

  const handleOpenCustomDialog = () => {
    setIsCustomDialogOpen(true);
  };

  const handleCloseCustomDialog = () => {
    setIsCustomDialogOpen(false);
  };

  const handleCustomAmountSubmit = () => {
    setCustomAmount(Math.max(1, Number(customAmount)));
    return setIsCustomDialogOpen(false);
  };

  const handleResetCustomAmount = () => {
    setCustomAmount(null);
  };

  const calculatedSubscriptionCost =
    customAmount !== null
      ? customAmount
      : Math.ceil((monthlySubscriptionCost * selectedPercentage) / 100);

  return (
    <>
      {selectedOffsetBucket && (
        <SubscriptionDetailsSlider
          selectedOffsetBucket={selectedOffsetBucket}
          setSelectedOffsetBucket={setSelectedOffsetBucket}
          SubscriptionPurchaseFlow={SubscriptionPurchaseFlow}
          calculatedSubscriptionCost={calculatedSubscriptionCost}
        />
      )}
      {cNaughtPortfoliosLoading ? (
        <DefaultPaper style={{ width: "100%" }}>
          <Grid container item justifyContent="center">
            <CircularProgress />
          </Grid>
        </DefaultPaper>
      ) : (
        <ReportContentCard
          title={`Offset My ${numPeople > 1 ? "Household's " : ""}Footprint`}
          subtitle="Choose your subscription or set a custom amount to offset your impact."
          isDesktop={isDesktop}
        >
          <Grid container gap={2} direction="column">
            <Grid item textAlign="center">
              <Typography variant="h4">{`$${calculatedSubscriptionCost}`}</Typography>
              <Typography variant="caption" color="textSecondary">
                per month
              </Typography>
            </Grid>

            <Grid container item textAlign="center" justifyContent="center">
              <Grid item xs={12} sm={6}>
                <Slider
                  value={selectedPercentage}
                  onChange={handleSliderChange}
                  step={25}
                  marks={[
                    { value: 25, label: "25%" },
                    { value: 50, label: "50%" },
                    { value: 75, label: "75%" },
                    { value: 100, label: "100%" },
                  ]}
                  min={25}
                  max={100}
                  valueLabelDisplay="auto"
                  disabled={customAmount !== null}
                />
              </Grid>
            </Grid>

            <Grid item textAlign="center">
              {customAmount !== null ? (
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={handleResetCustomAmount}
                >
                  Reset to Slider
                </Button>
              ) : (
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={handleOpenCustomDialog}
                >
                  Enter Custom Amount
                </Button>
              )}
            </Grid>

            <Grid container item gap={2} wrap="nowrap">
              {cNaughtPortfolios.map((portfolio, idx) => (
                <Grid item xs={12} sm={6} md={4} key={idx}>
                  <OffsetBucket
                    portfolio={portfolio}
                    setSelectedOffsetBucket={setSelectedOffsetBucket}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </ReportContentCard>
      )}

      <Dialog
        open={isCustomDialogOpen}
        onClose={handleCloseCustomDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Enter Custom Amount</DialogTitle>
        <DialogContent>
          <TextField
            size="small"
            variant="outlined"
            label="Custom Amount ($)"
            type="number"
            fullWidth
            value={customAmount || ""}
            onChange={(e) => setCustomAmount(e.target.value)}
            inputProps={{
              min: 1,
            }}
            helperText="Enter the amount you'd like to contribute per month."
            sx={{
              marginTop: 1,
            }}
          />
        </DialogContent>
        <DialogActions>
          {[
            {
              text: "Cancel",
              color: "secondary",
              onClick: handleCloseCustomDialog,
            },
            {
              text: "Submit",
              color: "primary",
              onClick: handleCustomAmountSubmit,
            },
          ].map(({ text, color, onClick }, idx) => (
            <Button key={idx} onClick={onClick} color={color} size="small">
              {text}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OffsetBucketsBox;
