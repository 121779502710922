import React from "react";

import { Grid, Typography } from "@mui/material";

import { DefaultPaper } from "@aclymatepackages/atoms";
import { useLayoutHelpers } from "../../helpers/otherHelpers";

const ReportContentCard = ({ title, subtitle, isDesktop, children }) => {
  const { theme } = useLayoutHelpers();

  return (
    <DefaultPaper
      style={{ height: "100%", width: "100%", opacity: 0.95 }}
      boxStyle={{ height: "100%" }}
    >
      <Grid container spacing={1} direction="column">
        {title && (
          <Grid item>
            <Typography variant="h3" align={isDesktop ? "left" : "center"}>
              {title}
            </Typography>
          </Grid>
        )}
        {subtitle && (
          <Grid item>
            <Typography
              variant="body1"
              align={isDesktop ? "left" : "center"}
              sx={{
                color: theme.palette.text.secondary,
              }}
            >
              {subtitle}
            </Typography>
          </Grid>
        )}
        <Grid item container justifyContent="center">
          {children}
        </Grid>
      </Grid>
    </DefaultPaper>
  );
};
export default ReportContentCard;
