import React, { useContext } from "react";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import advancedFormat from "dayjs/plugin/advancedFormat";

import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Area,
  Bar,
  ComposedChart,
  Line,
  Tooltip as ChartTooltip,
} from "recharts";

import { Grid, Box, useTheme } from "@mui/material";

import { formatDecimal } from "@aclymatepackages/formatters";
import { sumTonsCo2e } from "@aclymatepackages/other-helpers";
import {
  buildScopesRealDataObj,
  buildSubcategoriesDataObj,
  buildEmissionGroupData,
} from "@aclymatepackages/chart-helpers";
import {
  subcategories,
  otherSubcategoryObj,
} from "@aclymatepackages/subcategories";

import EmissionsCustomTooltip from "./EmissionsCustomTooltip";
import useChartWarningLabels from "./useChartWarningLabels";

import { PlatformLayoutContext } from "../../../helpers/contexts/platformLayout";
import { useAccountData } from "../../../helpers/firebase";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(quarterOfYear);
dayjs.extend(advancedFormat);

const findObjectValuesSum = (object) =>
  Object.values(object).reduce((sum, value) => sum + value, 0);

const buildGroupedChartData = ({
  groupedEmissions,
  buildRealDataObj,
  viewMode,
}) => {
  return groupedEmissions.map((emissionsArray, idx) => {
    const subcategoriesObj = buildRealDataObj(emissionsArray, [
      ...subcategories,
      otherSubcategoryObj,
    ]);

    const buildWarningObj = () => {
      const singleEmissionWarning = emissionsArray.find(
        ({ warning }) => !!warning
      );

      if (!singleEmissionWarning) {
        return null;
      }

      const { warning } = singleEmissionWarning;
      return warning;
    };

    return {
      ...subcategoriesObj,
      warning: buildWarningObj(),
      id: `emissions-chart-group-${idx}`,
      emissionsSumTons: sumTonsCo2e(emissionsArray),
      totalEmissionsSumTons: findObjectValuesSum(subcategoriesObj),
      viewMode,
    };
  });
};

const addTrendlineToChartData = (chartData) => {
  const averageX = (chartData.length - 1) / 2;
  const averageY =
    chartData.reduce(
      (sum, { totalEmissionsSumTons }) =>
        Number(sum) + Number(totalEmissionsSumTons),
      0
    ) / chartData.length;

  const trendLineCalcData = chartData.map(({ totalEmissionsSumTons }, idx) => {
    const xDifferenceFromMean = idx - averageX;
    const yDifferenceFromMean = totalEmissionsSumTons - averageY;
    const xDifferenceSquared = xDifferenceFromMean * xDifferenceFromMean;
    const xyDifferenceFromMean = xDifferenceFromMean * yDifferenceFromMean;
    return { xDifferenceSquared, xyDifferenceFromMean };
  });

  const xDifferenceSquaredSum = trendLineCalcData.reduce(
    (sum, { xDifferenceSquared }) => sum + xDifferenceSquared,
    0
  );
  const xyDifferenceFromMeanSum = trendLineCalcData.reduce(
    (sum, { xyDifferenceFromMean }) => sum + xyDifferenceFromMean,
    0
  );

  const trendlineSlope = xyDifferenceFromMeanSum / xDifferenceSquaredSum;

  const trendlineIntercept = averageY - trendlineSlope * averageX;

  const findTrendlineValue = (x) => trendlineSlope * x + trendlineIntercept;

  return chartData.map(({ totalEmissionsSumTons, ...otherProps }, idx) => ({
    ...otherProps,
    trendline: findTrendlineValue(idx),
  }));
};

const LabeledEmissionsChart = ({
  data,
  type,
  displayUnitLabel,
  chartArray,
  aspect = 3,
  showTooltip,
}) => {
  const { palette } = useTheme();

  const { warningLabels, labelSetter } = useChartWarningLabels({
    data,
    warningField: "warning",
    barSumField: "totalEmissionsSumTons",
  });

  const isTrendLineGood = data[0]?.trendLine > data[data.length - 1]?.trendLine;

  const ChartElement = type === "bar" ? Bar : Area;

  return (
    <Box position="relative">
      <ResponsiveContainer aspect={aspect}>
        <ComposedChart width={500} height={300} data={data}>
          <XAxis dataKey="label" interval="preserveStartEnd" height={20} />
          <YAxis
            tickFormatter={(tick) =>
              `${formatDecimal(tick)} ${displayUnitLabel}`
            }
            width={100}
          />
          <CartesianGrid strokeDasharray="3 3" />
          {showTooltip && (
            <ChartTooltip
              wrapperStyle={{ zIndex: 99 }}
              content={
                <EmissionsCustomTooltip
                  categoriesArray={chartArray}
                  displayUnitLabel={displayUnitLabel}
                />
              }
            />
          )}
          {chartArray.map(({ subcategory, color, ...otherProps }) => (
            <ChartElement
              key={`emissions-chart-element-${subcategory}`}
              type="monotone"
              stroke="none"
              fillOpacity={1}
              stackId="a"
              fill={color}
              dataKey={subcategory}
              {...otherProps}
            >
              {labelSetter}
            </ChartElement>
          ))}
          <Line
            name="6 Month Trend"
            connectNulls
            dataKey="trendline"
            stroke={
              isTrendLineGood ? palette.secondary.main : palette.error.main
            }
            strokeWidth={4}
            dot={false}
            strokeDasharray="5 5"
          />
        </ComposedChart>
      </ResponsiveContainer>
      {warningLabels}
    </Box>
  );
};

const EmissionsChart = ({
  dataArray: emissions,
  type,
  viewMode = "subcategories",
  graphPeriod,
  showTrendline,
  displayUnit,
  aspect,
  isPercentageChart,
  showTooltip = true,
  branding,
  netZeroPercentage,
  noLine = true,
}) => {
  const { convertCarbonUnits, displayUnitLabel } = useContext(
    PlatformLayoutContext
  );
  const [{ startDate }] = useAccountData();

  const {
    chartLabelsArray,
    scopesArray,
    subcategoriesArray,
    groupedEmissions,
  } = buildEmissionGroupData(emissions, graphPeriod, startDate, branding);

  const buildRealDataObj =
    viewMode === "subcategories"
      ? buildSubcategoriesDataObj
      : buildScopesRealDataObj;

  const buildChartData = () => {
    const preliminaryChartData = buildGroupedChartData({
      groupedEmissions,
      buildRealDataObj,
      viewMode,
    });

    const convertChartDataObject = (chartDataObj, converter) =>
      Object.fromEntries(
        Object.entries(chartDataObj).map(([key, value]) => {
          if (typeof value !== "number") {
            return [key, value];
          }
          return [key, converter(value)];
        })
      );

    const labelChartData = (chartData) =>
      chartData.map((data, idx) => ({
        ...data,
        label: chartLabelsArray[idx],
      }));

    if (isPercentageChart) {
      const percentageConvertedChartData = preliminaryChartData.map(
        (chartDataObj) => {
          const objectSubcategoryProperties = Object.keys(chartDataObj).filter(
            (key) =>
              subcategories.find(({ subcategory }) => subcategory === key)
          );
          const objectSubcategoryValues = objectSubcategoryProperties.map(
            (subcategory) => ({
              key: subcategory,
              value: chartDataObj[subcategory],
            })
          );

          const periodEmissionsSum = objectSubcategoryValues.reduce(
            (sum, { value }) => value + sum,
            0
          );

          const percentageConverter = (value) =>
            (value / periodEmissionsSum) * 100;

          const newObject = Object.fromEntries(
            objectSubcategoryValues.map(({ key, value }) => [key, value])
          );

          return convertChartDataObject(newObject, percentageConverter);
        }
      );
      return labelChartData(percentageConvertedChartData);
    }

    const unitConvertedChartData = preliminaryChartData.map((chartDataObj) =>
      convertChartDataObject(chartDataObj, convertCarbonUnits)
    );

    const labeledChartData = labelChartData(unitConvertedChartData);

    if (showTrendline) {
      return addTrendlineToChartData(labeledChartData);
    }

    return labeledChartData;
  };

  const chartData = buildChartData();

  const findFirstYearBaseline = () => {
    if (dayjs().diff(dayjs(startDate), "month") < 12 || noLine) {
      return false;
    }

    const { groupedEmissions: firstYearBaselineMonthlyEmissions } =
      buildEmissionGroupData(emissions, "month", startDate);

    const firstYearMonthlyGroupedEmissions = [...new Array(11)].map(
      (_, idx) => firstYearBaselineMonthlyEmissions[idx]
    );
    const firstYearMonthlyEmissionsSum = firstYearMonthlyGroupedEmissions.map(
      (emissions) => ({ tonsCo2e: sumTonsCo2e(emissions) })
    );
    const monthlyBaselineTons = sumTonsCo2e(firstYearMonthlyEmissionsSum) / 12;

    if (graphPeriod === "year") {
      return convertCarbonUnits(monthlyBaselineTons * 12);
    }

    if (graphPeriod === "quarter") {
      return convertCarbonUnits(monthlyBaselineTons * 3);
    }

    return convertCarbonUnits(monthlyBaselineTons);
  };

  const chartArray = viewMode === "scopes" ? scopesArray : subcategoriesArray;

  return (
    <Grid container direction="column">
      <Grid item>
        <LabeledEmissionsChart
          data={chartData}
          type={type}
          displayUnitLabel={
            isPercentageChart ? "%" : displayUnit || displayUnitLabel
          }
          chartArray={chartArray.map((obj) => ({ ...obj, viewMode }))}
          aspect={aspect}
          showTooltip={showTooltip}
          graphPeriod={graphPeriod}
          netZeroPercentage={netZeroPercentage}
          baseline={findFirstYearBaseline()}
        />
      </Grid>
    </Grid>
  );
};
export default EmissionsChart;
