import { useContext, useState } from "react";

import { Button } from "@mui/material";

import {
  faCheck,
  faEdit,
  faUserAltSlash,
  faUserClock,
  faUserEdit,
} from "@fortawesome/free-solid-svg-icons";

import { letterSBoolean } from "@aclymatepackages/formatters";
import {
  calcUtilitiesFromScheduleEndpoints,
  findScheduleEndpointsCommuteEmissionsTons,
} from "@aclymatepackages/calcs/recurring";
import { calcAvgEmissionsPastSixMonths } from "@aclymatepackages/date-helpers";
import { mainTheme } from "@aclymatepackages/themes";
import { STARTER_TIER_MAXIMUM_NUMBER_EMPLOYEES } from "@aclymatepackages/constants";

import Link from "../../components/atoms/mui/Link";

import {
  firebaseFieldDeleteObj,
  useCachedFirebaseCrud,
  useCachedDisplayData,
  useAccountData,
} from "../firebase";
import { fetchOurApi } from "../utils/apiCalls";
import useDashboardDataContext from "../contexts/dashboardData";
import { PlatformLayoutContext } from "../contexts/platformLayout";
import {
  useCompletedOnboardingSteps,
  useStarterTierSubscriptionFlags,
} from "../hooks/companyData";
import { getAccountCollectionAndId } from "../otherHelpers";

export const employeeStatuses = {
  confirmed: {
    id: "confirmed",
    icon: faCheck,
    tooltip:
      "We have all of the information that we need to track this employee's emissions",
    color: mainTheme.palette.secondary.main,
    severity: 2,
    name: "Confirmed Employees",
  },
  unconfirmed: {
    id: "unconfirmed",
    icon: faUserEdit,
    tooltip:
      "You've sent this employee a survey but they haven't filled it out yet. In the meantime, we will display default emissions for this employee.",
    color: mainTheme.palette.error.main,
    severity: 4,
    name: "Unconfirmed Employees",
  },
  incomplete: {
    id: "incomplete",
    icon: faEdit,
    tooltip:
      "This employee's information is incomplete. Please send them another survey. In the meantime, we will display their most recent emissions.",
    color: mainTheme.palette.error.light,
    severity: 3,
    name: "Incomplete Employees",
  },
  onLeave: {
    id: "onLeave",
    icon: faUserClock,
    tooltip: "This employee is currently on leave",
    color: mainTheme.palette.secondary.light,
    severity: 1,
    name: "Employees on Leave",
  },
  terminated: {
    id: "terminated",
    icon: faUserAltSlash,
    tooltip: "This employee no longer works for you",
    color: mainTheme.palette.error.dark,
    severity: 0,
    name: "Terminated Employees",
  },
};

export const sendEmployeeSurvey = async ({
  setSendSurveyLoading,
  employee,
}) => {
  const { id: companyId } = getAccountCollectionAndId();

  if (setSendSurveyLoading) {
    setSendSurveyLoading(true);
  }

  await fetchOurApi({
    path: `/survey-emails/employee/invite`,
    method: "POST",
    data: {
      employee,
      companyId,
    },
  });

  if (setSendSurveyLoading) {
    return setSendSurveyLoading(false);
  }

  return null;
};

export const modifyEmployeeVehiclesInCommuteSchedules = ({
  commuteSchedules = [],
  newVehicle,
  updatedVehicles,
}) =>
  commuteSchedules.map((commuteSchedule) => {
    const newCommuteEndpoint = commuteSchedule.commuteEndpoints.map(
      (commuteEndpoint) => {
        const { vehicles: commuteEndpointVehicles = [] } = commuteEndpoint;

        const newCommuteEndpointsVehicles = commuteEndpointVehicles.map(
          (commuteVehicle) => {
            const { id: commuteVehicleId } = commuteVehicle;
            const findCommuteVehicle = updatedVehicles
              ? updatedVehicles.find(
                  ({ id: vehicleId }) => vehicleId === commuteVehicleId
                )
              : newVehicle.id === commuteVehicleId;

            if (findCommuteVehicle) {
              const {
                name: modifiedCommuteVehicleName,
                tonsCo2ePerMile: modifiedCommuteVehicleTonsCo2ePerMile,
              } = updatedVehicles ? findCommuteVehicle : newVehicle;

              return {
                ...commuteVehicle,
                id: commuteVehicleId,
                name: modifiedCommuteVehicleName,
                tonsCo2ePerMile: modifiedCommuteVehicleTonsCo2ePerMile,
              };
            }

            return commuteVehicle;
          }
        );

        const commuteEndpointVehiclesUpdateObj =
          newCommuteEndpointsVehicles.length
            ? { vehicles: newCommuteEndpointsVehicles }
            : {};

        return {
          ...commuteEndpoint,
          ...commuteEndpointVehiclesUpdateObj,
        };
      }
    );

    return {
      ...commuteSchedule,
      commuteEndpoints: newCommuteEndpoint,
    };
  });

export const buildNewEmployeeStatusLinks = (
  { links },
  newStatus,
  givenCompanyId
) => {
  const { id: companyId } = getAccountCollectionAndId();

  return links.map((link) =>
    link.id === givenCompanyId || companyId
      ? { ...link, employeeStatus: newStatus }
      : link
  );
};

const useEmployeeNotifications = (displayEmployees, setAddEmployees) => {
  const [{ employeesComplete }] = useCompletedOnboardingSteps();
  const [{ employeeCount, employeeCountConfirmed }] = useAccountData();
  const [employees] = useCachedDisplayData("employees");
  const { updateAccountData } = useCachedFirebaseCrud();
  const { isEmployeeLimitReached } = useStarterTierSubscriptionFlags();

  const [activeAlert, setActiveAlert] = useState(
    window.sessionStorage.getItem("employeeAlert")
  );

  const onAlertClick = (option) => {
    if (option === "no-more") {
      return updateAccountData({
        employeeCount: firebaseFieldDeleteObj(),
        employeeCountConfirmed: firebaseFieldDeleteObj(),
      });
    }

    setAddEmployees(true);
    return setActiveAlert(() => {
      window.sessionStorage.setItem("employeeAlert", employees.length);
      return employees.length;
    });
  };

  const buildEmployeeCountAlert = () => {
    if (
      !employeeCountConfirmed &&
      employeesComplete &&
      employeeCount &&
      employees.length < employeeCount &&
      employees.length !== activeAlert
    ) {
      const alertActions = [
        {
          label: "no more employees",
          onClick: () => onAlertClick("no-more"),
          variant: "outlined",
        },
        { label: "continue adding", onClick: () => onAlertClick("continue") },
      ];

      return [
        {
          title: "Are you finished adding all of your company's employees?",
          subtitle: `You've only invited ${employees.length}${letterSBoolean(
            employees
          )} to your company, but you said you had ${employeeCount} employee${letterSBoolean(
            employeeCount
          )}. Are you done adding your employees or are you waiting to add more?`,
          action: (
            <>
              {alertActions.map(({ label, ...buttonProps }, idx) => (
                <Button
                  key={`employee-count-action-button-${idx}`}
                  color="inherit"
                  size="small"
                  {...buttonProps}
                >
                  {label}
                </Button>
              ))}
            </>
          ),
        },
      ];
    }

    return [];
  };

  const buildSeverityAlert = () => {
    const highSeverityEmployees = displayEmployees.filter(
      ({ severity }) => severity > 2
    );

    if (highSeverityEmployees.length) {
      return [
        {
          title: `${highSeverityEmployees.length} employee${letterSBoolean(
            highSeverityEmployees
          )} need more information`,
          subtitle:
            "We're estimating emissions for these employees because we're still waiting for information from them.",
        },
      ];
    }

    return [];
  };

  const buildStarterTierEmployeeLimitAlert = () => {
    if (isEmployeeLimitReached) {
      return [
        {
          title: `You have reached the ${STARTER_TIER_MAXIMUM_NUMBER_EMPLOYEES} employee limit for the Starter plan`,
          subtitle: "Upgrade your subscription to add more employees",
          action: (
            <Link href="/platform/company/settings/products">
              <Button color="inherit" size="small">
                Upgrade
              </Button>
            </Link>
          ),
        },
      ];
    }

    return [];
  };

  const employeeCountAlert = buildEmployeeCountAlert();
  const highSeverityEmployeesAlert = buildSeverityAlert();
  const starterTierEmployeeLimitAlert = buildStarterTierEmployeeLimitAlert();

  return [
    ...starterTierEmployeeLimitAlert,
    ...employeeCountAlert,
    ...highSeverityEmployeesAlert,
  ];
};

export const useEmployeeStatuses = () => {
  const { id: companyId } = getAccountCollectionAndId();
  const [employees, employeesLoading] = useCachedDisplayData("employees");

  const employeesWithStatuses = employees.map((employee = {}) => {
    const { links = [] } = employee;
    const { employeeStatus } = links.find(({ id }) => id === companyId) || {};

    return { ...employee, status: employeeStatus };
  });

  return [employeesWithStatuses, employeesLoading];
};

export const useFindCurrentEmployeeEmissions = () => {
  const { convertCarbonUnits } = useContext(PlatformLayoutContext);

  return ({ employees, emissionsMonths, employeeCount, transactionsObj }) => {
    if (!employees.length) {
      return [];
    }

    const filterEmployeesByStatus = (status) =>
      employees.filter((employee) => employee.status === status);

    const confirmedEmployees = filterEmployeesByStatus("confirmed");
    const unconfirmedEmployees = filterEmployeesByStatus("unconfirmed");
    const terminatedEmployees = filterEmployeesByStatus("terminated");
    const onLeaveEmployees = filterEmployeesByStatus("onLeave");
    const incompleteEmployees = filterEmployeesByStatus("incomplete");

    const notUnconfirmedEmployeesCount =
      employees.length - unconfirmedEmployees.length;

    const sumEndpointTotalTons = (endpoints) =>
      endpoints
        .filter((data) => data)
        .reduce((sum, { totalTons }) => totalTons + sum, 0);

    const findCurrentScheduleMonthlyEmissions = ({ commuteSchedules }) => {
      const defaultReturnObj = {
        monthlyUtilitiesEmissionsVolume: 0,
        monthlyCommuteEmissionsVolume: 0,
      };

      if (!commuteSchedules?.length) {
        return defaultReturnObj;
      }

      const schedulesWithEndpoints = commuteSchedules.filter(
        ({ commuteEndpoints }) => commuteEndpoints?.length
      );

      const [currentSchedule] = schedulesWithEndpoints;
      if (!currentSchedule) {
        return defaultReturnObj;
      }
      const { home, commuteEndpoints } = currentSchedule;

      const monthlyUtilitiesEmissionsTonsArray =
        calcUtilitiesFromScheduleEndpoints({ commuteEndpoints, ...home });

      const monthlyUtilitiesEmissionsTons = sumEndpointTotalTons(
        monthlyUtilitiesEmissionsTonsArray
      );

      const { address } = home;
      const monthlyCommuteEmissionsTonsArray =
        findScheduleEndpointsCommuteEmissionsTons({
          commuteEndpoints,
          employeeAddress: address,
        });

      const monthlyCommuteEmissionsTons = sumEndpointTotalTons(
        monthlyCommuteEmissionsTonsArray
      );

      return {
        monthlyUtilitiesEmissionsVolume: convertCarbonUnits(
          monthlyUtilitiesEmissionsTons
        ),
        monthlyCommuteEmissionsVolume: convertCarbonUnits(
          monthlyCommuteEmissionsTons
        ),
      };
    };

    const findOnLeaveEmployeesMostRecentEmissions = (employee) => {
      const { monthlyUtilitiesEmissionsVolume, monthlyCommuteEmissionsVolume } =
        findCurrentScheduleMonthlyEmissions(employee);

      const totalMonthlyVolume =
        monthlyCommuteEmissionsVolume + monthlyUtilitiesEmissionsVolume;

      return {
        ...employee,
        mostRecentEmissionsVolume: totalMonthlyVolume,
        totalMonthlyVolume,
      };
    };

    //TODO: there appears to be a huge bug at some point when employees are converted to incomplete that deletes the commute endpoints in the most recent schedule
    const balanceEmployeeEmissions = [
      ...confirmedEmployees,
      ...incompleteEmployees,
    ].map((employee) => {
      const { id } = employee;

      const { monthlyUtilitiesEmissionsVolume, monthlyCommuteEmissionsVolume } =
        findCurrentScheduleMonthlyEmissions(employee);

      const employeeTransactions = transactionsObj[`employee_${id}`] || [];

      const calcTransactionsAverageVolumeBySubcategory = (subcategory) =>
        convertCarbonUnits(
          calcAvgEmissionsPastSixMonths(
            employeeTransactions.filter(
              (transaction) => transaction.subcategory === subcategory
            )
          )
        );

      const flightsTransactionsAverage =
        calcTransactionsAverageVolumeBySubcategory("flights");
      const rentalCarTransactionsAverage =
        calcTransactionsAverageVolumeBySubcategory("rental-cars");
      const ridesTransactionsAverage =
        calcTransactionsAverageVolumeBySubcategory("rides");
      const mileageTransactionsAverage =
        calcTransactionsAverageVolumeBySubcategory("mileage");
      const fuelTransactionsAverage =
        calcTransactionsAverageVolumeBySubcategory("fuel");

      const transactionsAverageMonthlyEmissionsVolume = convertCarbonUnits(
        calcAvgEmissionsPastSixMonths(employeeTransactions)
      );

      const averageMonthlyNonCommuteEmissionsVolume =
        transactionsAverageMonthlyEmissionsVolume +
        monthlyUtilitiesEmissionsVolume;

      return {
        ...employee,
        averageMonthlyNonCommuteEmissionsVolume,
        monthlyUtilitiesEmissionsVolume,
        monthlyCommuteEmissionsVolume,
        avgMonthlyFlightsEmissions: flightsTransactionsAverage,
        avgMonthlyRentalCarEmissions: rentalCarTransactionsAverage,
        avgMonthlyRidesEmission: ridesTransactionsAverage,
        avgMonthlyMileageEmissions: mileageTransactionsAverage,
        avgMonthlyFuelEmissions: fuelTransactionsAverage,
        totalMonthlyVolume:
          monthlyCommuteEmissionsVolume +
          monthlyUtilitiesEmissionsVolume +
          transactionsAverageMonthlyEmissionsVolume,
      };
    });

    const onLeaveEmployeeEmissions = onLeaveEmployees.map((employee) =>
      findOnLeaveEmployeesMostRecentEmissions(employee)
    );

    const defaultEmployeesCount = employeeCount
      ? employeeCount - notUnconfirmedEmployeesCount
      : unconfirmedEmployees.length;

    const { defaultEmissions } = emissionsMonths[0] || {};
    const { monthlyEmployeesEmissionsTons } = defaultEmissions || {};

    const unconfirmedEmployeeDefaultVolume = convertCarbonUnits(
      monthlyEmployeesEmissionsTons / defaultEmployeesCount
    );

    const unconfirmedEmployeesDefaultEmissions = unconfirmedEmployees.map(
      (employee) => ({
        ...employee,
        defaultTotalEmissions: unconfirmedEmployeeDefaultVolume,
        totalMonthlyVolume: unconfirmedEmployeeDefaultVolume,
      })
    );

    return [
      ...terminatedEmployees.map((employee) => ({
        ...employee,
        totalMonthlyVolume: 0,
      })),
      ...onLeaveEmployeeEmissions,
      ...unconfirmedEmployeesDefaultEmissions,
      ...balanceEmployeeEmissions,
    ].map((employee) => {
      const { status } = employee;
      const { severity } = employeeStatuses[status];

      return { ...employee, severity };
    });
  };
};

export const useEmployeesData = () => {
  const { employees, dashboardDataLoading } = useDashboardDataContext();

  const [addEmployees, setAddEmployees] = useState(false);

  const employeeNotifications = useEmployeeNotifications(
    employees,
    setAddEmployees
  );

  return {
    employeesLoading: dashboardDataLoading,
    addEmployees,
    setAddEmployees,
    employeeNotifications,
    employees,
  };
};
