import React, { useContext } from "react";

import {
  Box,
  Grid,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  useTheme,
} from "@mui/material";

import WarningIcon from "@mui/icons-material/Warning";

import {
  formatDate,
  formatDecimal,
  ucFirstLetters,
} from "@aclymatepackages/formatters";
import { CategoriesAvatar, Checkbox } from "@aclymatepackages/atoms";

import { PlatformLayoutContext } from "../../../helpers/contexts/platformLayout";

const SeparateUtilitiesDefaultEmissionCategoryWarning = () => {
  const theme = useTheme();
  const { palette } = theme;

  return (
    <Tooltip title="This emission was created as a default emission since you haven't uploaded utilities for this month yet.">
      <Avatar
        style={{
          height: "20px",
          width: "20px",
          fontSize: "0.9rem",
          border: "solid thin white",
          color: "white",
          backgroundColor: palette.mileage.main,
          position: "absolute",
          top: "0",
          right: "0",
        }}
      >
        <WarningIcon style={{ fontSize: "inherit" }} />
      </Avatar>
    </Tooltip>
  );
};

const EmissionDetailsTableRow = ({
  transaction,
  setSelectedTransaction,
  closeSelectedObjectSlider,
  onSelectRow,
  isSelected,
}) => {
  const { palette } = useTheme();

  const {
    date,
    name,
    source,
    vendor,
    subcategory,
    tonsCo2e = 0,
    value,
    electricRenewablesPercentage,
  } = transaction;

  const { convertCarbonUnits } = useContext(PlatformLayoutContext);

  const isClickable =
    (source === "csv-upload" || source === "manual-entry") &&
    setSelectedTransaction;

  const openTransactionsDetailSlider = () => {
    if (!isClickable) {
      return;
    }

    setSelectedTransaction(transaction);
    return closeSelectedObjectSlider();
  };

  return (
    <TableRow
      key={name}
      hover={isClickable}
      onClick={() => openTransactionsDetailSlider()}
      style={{ cursor: isClickable ? "pointer" : "inherit" }}
    >
      {onSelectRow && (
        <TableCell padding="checkbox">
          <Checkbox value={isSelected} editValue={onSelectRow} />
        </TableCell>
      )}
      <TableCell scope="row">{formatDate(date)}</TableCell>
      <TableCell>{vendor || name}</TableCell>
      <TableCell align="right">
        <Grid container direction="row">
          <Grid item>
            <Box position="relative">
              <CategoriesAvatar
                subcategory={subcategory}
                color={
                  electricRenewablesPercentage === 100 && palette.secondary.main
                }
                tooltip={
                  electricRenewablesPercentage === 100 &&
                  `${
                    source === "default-utilities" ? "We predict t" : "T"
                  }his utility emission was 100% offset by renewables.`
                }
              />
              {source === "default-utilities" && (
                <SeparateUtilitiesDefaultEmissionCategoryWarning />
              )}
            </Box>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="right">
        {value ?? formatDecimal(Math.abs(convertCarbonUnits(tonsCo2e)))}
      </TableCell>
    </TableRow>
  );
};

const EmissionsTable = ({
  headers = [],
  setSelectedTransaction,
  closeSelectedObjectSlider,
  emissions,
  onSelectRow,
  selectedRows = [],
  noSort,
}) => {
  const { displayUnitLabel } = useContext(PlatformLayoutContext);

  const selectColumnHeader = onSelectRow ? [{}] : [];

  const tableLabelRows = !headers.length
    ? [
        ...selectColumnHeader,
        { label: "Date", align: "inherit" },
        { label: "Name", align: "inherit" },
        { label: "Category", align: "inherit" },
        { label: `${ucFirstLetters(displayUnitLabel)} CO2`, align: "right" },
      ]
    : headers;

  const sortedEmissions = !noSort
    ? [...emissions].sort((a, b) => b.date - a.date)
    : emissions;

  return (
    <TableContainer
      style={{
        maxHeight: "300px",
      }}
    >
      <Table
        style={{
          minWidth: 250,
          maxHeight: "300px",
        }}
        stickyHeader
      >
        <TableHead>
          <TableRow>
            {tableLabelRows.map(({ label, align }, idx) => (
              <TableCell key={`emissions-table-head-${idx}`} align={align}>
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedEmissions.map((transaction, index) => (
            <EmissionDetailsTableRow
              transaction={transaction}
              setSelectedTransaction={setSelectedTransaction}
              closeSelectedObjectSlider={closeSelectedObjectSlider}
              key={`emissions-details-table-row-${index}`}
              onSelectRow={onSelectRow && onSelectRow(index)}
              isSelected={selectedRows[index]}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default EmissionsTable;
