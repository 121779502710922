import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import { Box, Button, Grid, Typography, Paper } from "@mui/material";

import { useLayoutHelpers } from "@aclymatepackages/themes";
import { tonsToLbs } from "@aclymatepackages/converters";
import { formatDecimal } from "@aclymatepackages/formatters";

const WorkspaceCTABlock = ({
  isDesktop,
  yearlyWorkRelatedTonsCo2e,
  learnMoreOnClick,
  invitedCompanyName,
  individualAccountCreationLink,
  doesAclymateAccountAlreadyExist,
}) => {
  const { theme } = useLayoutHelpers();

  const otherSvgProps = !isDesktop
    ? { backgroundPosition: "center bottom", height: "150px" }
    : {};

  const buttonOnClick = individualAccountCreationLink
    ? { href: individualAccountCreationLink }
    : { onClick: () => learnMoreOnClick() };
  const buttonText = doesAclymateAccountAlreadyExist
    ? "Login"
    : "Create an account";

  return (
    <Paper>
      <Grid container alignItems="stretch">
        <Grid item md={7}>
          <Box p={isDesktop ? 4 : 2}>
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <Typography
                  variant={isDesktop ? "h4" : "h5"}
                  align={isDesktop ? "left" : "center"}
                >
                  {`Your work related emissions account for ${formatDecimal(
                    tonsToLbs(yearlyWorkRelatedTonsCo2e)
                  )} lbs of your total
                  yearly footprint.`}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant={isDesktop ? "h6" : "body2"}
                  color="textSecondary"
                  align={isDesktop ? "left" : "center"}
                >
                  {`They can help you offset your work-related emissions. And ever wonder how big your company's footprint must be?${
                    individualAccountCreationLink
                      ? " Create a MyAclymate account to learn more."
                      : ""
                  }`}
                </Typography>
              </Grid>
              {invitedCompanyName && (
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <FontAwesomeIcon
                      icon={faCheck}
                      color={theme.palette.secondary.main}
                      size="2x"
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant={isDesktop ? "h6" : "body2"}>
                      {`You are linked to ${invitedCompanyName}.`}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid item container justifyContent="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    {...buttonOnClick}
                  >
                    {individualAccountCreationLink ? buttonText : "Learn More"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          md={5}
          xs={12}
          style={{
            backgroundImage: "url('images/platform/new-team-members.svg')",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right bottom",
            ...otherSvgProps,
          }}
        />
      </Grid>
    </Paper>
  );
};
export default WorkspaceCTABlock;
