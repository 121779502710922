import React, { useState } from "react";
import dayjs from "dayjs";

import {
  Box,
  Grid,
  FormLabel,
  Avatar,
  useTheme,
  Typography,
  IconButton,
} from "@mui/material";
import {
  LoadingButton,
  Select,
  TextField,
  ToggleButtons,
} from "@aclymatepackages/atoms";
import DatePicker from "../../atoms/mui/DatePicker";
import SlideLayout from "../../layouts/SlideLayout";
import UnitsSelect from "../UnitsSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useCachedFirebaseCrud } from "../../../helpers/firebase";
import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { faCloud } from "@fortawesome/pro-solid-svg-icons";
import { subcategories } from "@aclymatepackages/subcategories";
import DeleteIcon from "@mui/icons-material/Delete";

const EmissionsVolumeForm = ({ setDialogOpen, existingEmission = {} }) => {
  const { palette } = useTheme();
  const [emission, setEmission] = useState(existingEmission || {});
  const [selectType, setSelectType] = useState("scope");
  const [isLoading, setIsLoading] = useState(false);
  const { newCollectionDoc, updateCollectionDoc, deleteCachedCollectionDoc } =
    useCachedFirebaseCrud();

  const {
    date,
    name,
    volume,
    volumeUnit = "tons",
    subcategory,
    scope,
  } = emission || {};

  const editEmissionData = (field) => (value) =>
    editObjectData(setEmission, field, value);

  const inputSubcategories = subcategories.filter(({ input }) => input);

  const selectContent = [
    {
      type: "scope",
      label: "Scopes",
      options: [
        { value: 1, label: "Scope 1" },
        { value: 2, label: "Scope 2" },
        { value: 3, label: "Scope 3" },
      ],
      value: scope,
      editValue: editEmissionData("scope"),
    },
    {
      type: "subcategories",
      label: "Category",
      options: inputSubcategories.map(({ name, subcategory }) => ({
        value: subcategory,
        label: name,
      })),
      value: subcategory,
      editValue: editEmissionData("subcategory"),
    },
  ];

  const selectedContent = selectContent.find(({ type }) => type === selectType);

  const emissionToggleButtons = selectContent.map(({ type, label }) => ({
    value: type,
    name: label,
  }));

  const disabled =
    !date || !name || !volume || !volumeUnit || (!subcategory && !scope);

  const doesEmissionExist = Object.keys(existingEmission).length > 0;

  const onEmissionSave = async () => {
    if (disabled) {
      return;
    }
    setIsLoading(true);

    if (doesEmissionExist) {
      await updateCollectionDoc("emissions", existingEmission.id, {
        date: dayjs(date).toDate(),
        name,
        volume: Number(volume),
        volumeUnit,
        subcategory,
        scope,
      });

      setIsLoading(false);
      return setDialogOpen(null);
    }

    newCollectionDoc("emissions", {
      date: dayjs(date).toDate(),
      status: "confirmed",
      archived: false,
      name,
      volume: Number(volume),
      volumeUnit,
      subcategory,
      scope,
    });

    return setDialogOpen(null);
  };

  const onEmissionDelete = () => {
    deleteCachedCollectionDoc("emissions", existingEmission.id);
    return setDialogOpen(false);
  };

  return (
    <SlideLayout
      isSlideOpen
      setIsSlideOpen={setDialogOpen}
      header={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Avatar
            sx={{
              bgcolor: palette.secondary.main,
            }}
          >
            <FontAwesomeIcon icon={faCloud} />
          </Avatar>
          <Typography variant="h5">Emissions by Volume</Typography>
        </Box>
      }
      tagInputs={
        <Box px={2}>
          <Grid container direction="column" spacing={2}>
            <Grid item container spacing={2}>
              <Grid item xs={5}>
                <DatePicker
                  label="Emission Date"
                  date={date}
                  editDate={editEmissionData("date")}
                  maxDate={new Date()}
                />
              </Grid>

              <Grid item xs={7}>
                <TextField
                  label="Emission Name"
                  large
                  value={name}
                  setValue={editEmissionData("name")}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      }
      content={
        <Grid container direction="column" gap={2}>
          <Grid item>
            <Grid container direction="column" gap={1}>
              <Grid item>
                <FormLabel>
                  How many tons of CO2e were emitted in this transaction?
                </FormLabel>
              </Grid>
              <Grid item>
                <Grid container alignItems={"center"} gap={2} wrap="nowrap">
                  <Grid item xs={9}>
                    <TextField
                      label="Emission Volume"
                      value={volume}
                      setValue={editEmissionData("volume")}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <UnitsSelect
                      value={volumeUnit}
                      setValue={editEmissionData("volumeUnit")}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column" gap={1}>
              <Grid item>
                <FormLabel>
                  What is the category or scope of this emission?
                </FormLabel>
              </Grid>
              <Grid item>
                <Grid container alignItems={"center"} gap={2} wrap="nowrap">
                  <Grid item xs={9}>
                    <Select
                      size="small"
                      label={selectedContent.label}
                      value={selectedContent.value}
                      editValue={selectedContent.editValue}
                      options={selectedContent.options}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <ToggleButtons
                      value={selectType}
                      onChange={setSelectType}
                      buttons={emissionToggleButtons}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      footer={
        <Box
          sx={{
            display: "flex",
          }}
        >
          {doesEmissionExist && (
            <IconButton
              onClick={() => onEmissionDelete()}
              sx={{
                justifySelf: "flex-start",
              }}
            >
              <DeleteIcon style={{ color: palette.error.main }} />
            </IconButton>
          )}
          <Box
            sx={{
              marginLeft: "auto",
            }}
          >
            <LoadingButton
              variant="contained"
              color="primary"
              size="small"
              onClick={onEmissionSave}
              isLoading={isLoading}
              disabled={disabled}
              label="Save Emission"
            />
          </Box>
        </Box>
      }
    />
  );
};

export default EmissionsVolumeForm;
