import React, { useState, createContext, useEffect, useCallback } from "react";

import { fetchOurApi } from "../utils/apiCalls";
import { useCustomerPaymentMethod } from "../hooks/stripe";

import { useAccountData } from "../firebase";

export const StripeCustomerContext = createContext();

export const StripeCustomerContextProvider = ({ children }) => {
  const [{ stripeCustomerId }, accountDataLoading] = useAccountData();

  const {
    paymentMethod,
    isPaymentMethodLoading,
    refetchCustomerPaymentMethod,
  } = useCustomerPaymentMethod();

  const [isSubscriptionsLoading, setIsSubscriptionsLoading] = useState(true);
  const [subscriptions, setSubscriptions] = useState([]);

  const refetchCustomerSubscriptions = useCallback(
    async (givenStripeCustomerId) => {
      const customerId = givenStripeCustomerId || stripeCustomerId;

      if (!customerId) {
        return setIsSubscriptionsLoading(false);
      }

      return await fetchOurApi({
        path: "/stripe/get-customer-subscriptions",
        method: "POST",
        data: {
          customerId,
        },
        callback: (subscriptions) => {
          if (!!subscriptions && subscriptions.length) {
            setSubscriptions(subscriptions);
          }

          return setIsSubscriptionsLoading(false);
        },
      });
    },
    [stripeCustomerId]
  );

  useEffect(() => {
    if (
      !accountDataLoading &&
      isSubscriptionsLoading &&
      !subscriptions.length
    ) {
      refetchCustomerSubscriptions(stripeCustomerId);
    }
  }, [
    accountDataLoading,
    isSubscriptionsLoading,
    stripeCustomerId,
    subscriptions,
    refetchCustomerSubscriptions,
  ]);

  return (
    <StripeCustomerContext.Provider
      value={{
        subscriptions,
        isSubscriptionsLoading,
        refetchCustomerSubscriptions,
        paymentMethod,
        isPaymentMethodLoading,
        refetchCustomerPaymentMethod,
        isNotStripeCustomer: !stripeCustomerId,
      }}
    >
      {children}
    </StripeCustomerContext.Provider>
  );
};
