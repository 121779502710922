import React from "react";

import { Tooltip } from "@mui/material";

import { ucFirstLetters, formatDecimal } from "@aclymatepackages/formatters";
import { useLayoutHelpers } from "@aclymatepackages/themes";
import {
  US_AVERAGE_PER_CAPITA_HOME_TONS_CO2E_PER_YEAR,
  US_AVERAGE_VEHICLE_TRAVEL_TONS_CO2E_PER_YEAR,
  US_AVERAGE_FLIGHTS_TONS_CO2E_PER_YEAR,
  US_AVERAGE_DIET_TONS_CO2E_PER_YEAR,
  US_AVERAGE_EXPENDITURES_PER_CAPITA_TONS_CO2E_PER_YEAR,
} from "@aclymatepackages/constants";
import { tonsToLbs } from "@aclymatepackages/converters";

import "./blinkingToe.css";

const footprintColors = [
  {
    category: "home",
    color: "offices",
  },
  { category: "travel", color: "vehicles" },
  {
    category: "flights",
    color: "flights",
  },
  {
    category: "diet",
    color: "summary",
  },
  {
    category: "costs",
    color: "primary",
  },
];

const CarbonFoot = ({
  totalFootprintTons,
  footprintDetails,
  onCategorySelect,
  isDesktop,
  disableToeTonsCo2eSorting,
  customSvgProps,
  numPeople,
}) => {
  const { theme } = useLayoutHelpers();
  const { palette } = theme;

  const isThereMoreThanOnePerson = numPeople && numPeople > 1;

  const lightSkinTone = theme.palette.backgroundGray.main;
  const darkSkinTone = theme.palette.backgroundGray.dark;

  const US_AVERAGE_COMPARISON =
    US_AVERAGE_PER_CAPITA_HOME_TONS_CO2E_PER_YEAR +
    US_AVERAGE_VEHICLE_TRAVEL_TONS_CO2E_PER_YEAR +
    US_AVERAGE_FLIGHTS_TONS_CO2E_PER_YEAR +
    US_AVERAGE_DIET_TONS_CO2E_PER_YEAR +
    US_AVERAGE_EXPENDITURES_PER_CAPITA_TONS_CO2E_PER_YEAR;

  const footprintIsGreaterThanComparison =
    totalFootprintTons > US_AVERAGE_COMPARISON;

  const toesLayoutProps = [
    {
      ellipse: {
        cx: 39.5,
        cy: 52.5,
        rx: 39.5,
        ry: 52.5,
        transform: "translate(-17, 56.24) rotate(-34.28)",
      },
      text: { fontSize: "23", x: "14.61", y: "58" },
      tSpan: { x: "45.5", y: "84" },
    },
    {
      ellipse: {
        cx: "30.61",
        cy: "45.06",
        rx: "30.61",
        ry: "45.06",
        transform: "translate(80.72, 5.48) rotate(-7.37)",
      },
      text: { fontSize: "18.5", x: "92.35", y: "30" },
      tSpan: { x: "116.5", y: "51" },
    },
    {
      ellipse: {
        cx: "25.07",
        cy: "35.54",
        rx: "25.07",
        ry: "35.54",
        transform: "translate(156.89, 14.46) rotate(4.3)",
      },
      text: { fontSize: "14", x: "160.99", y: "40" },
      tSpan: { x: "179.5", y: "60" },
    },
    {
      ellipse: {
        cx: "22.45",
        cy: "31.81",
        rx: "22.45",
        ry: "31.81",
        transform: "translate(218.84, 34.63) rotate(15.99)",
      },
      text: { fontSize: "12", x: "215.76", y: "56" },
      tSpan: { x: "232.5", y: "76" },
    },
    {
      ellipse: {
        cx: "20.59",
        cy: "29.75",
        rx: "21.59",
        ry: "30.75",
        transform: "translate(277.18, 67.3) rotate(39.56)",
      },
      text: { fontSize: "13", x: "255.99", y: "90" },
      tSpan: { x: "273.5", y: "109" },
    },
  ];

  const sortedFootprintDetails = disableToeTonsCo2eSorting
    ? footprintDetails
    : [...footprintDetails].sort((a, b) => b.tonsCo2e - a.tonsCo2e);

  const toeColors = sortedFootprintDetails.map(
    ({ category, ...otherProps }) => {
      const { color } = footprintColors.find(
        (row) => row.category === category
      );

      return { color, category, ...otherProps };
    }
  );

  const otherSvgProps = customSvgProps
    ? customSvgProps
    : isDesktop
    ? {
        height: "650px",
      }
    : { height: "400px" };

  return (
    <>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 300 500"
        xmlSpace="preserve"
        id="carbonFootprint"
        {...otherSvgProps}
      >
        {toesLayoutProps.map(({ ellipse, text, tSpan }, idx) => {
          const toeNumber = idx + 1;
          const {
            category,
            color,
            shade,
            tonsCo2e,
            isComplete = true,
            isCurrentStep,
          } = toeColors[idx];

          const blinkingToeClassObj = isCurrentStep
            ? { class: "blinkingToe" }
            : {};

          return (
            <Tooltip
              key={`carbon-footprint-toe-${toeNumber}`}
              title={`${ucFirstLetters(category)}: ${formatDecimal(
                tonsToLbs(tonsCo2e)
              )} lbs CO2`}
            >
              <g>
                <ellipse
                  cursor="pointer"
                  id={`carbon-footprint-toe-${toeNumber}`}
                  stroke="none"
                  fill={
                    isComplete
                      ? palette[color][shade || "main"]
                      : palette.backgroundGray.dark
                  }
                  onClick={() => onCategorySelect && onCategorySelect(category)}
                  {...blinkingToeClassObj}
                  {...ellipse}
                />
                <text
                  fill="rgb(255, 255, 255)"
                  fontFamily="Poppins, HelveticaNeue, 'Helvetica Neue', Helvetica, Arial, sans-serif"
                  textAnchor="middle"
                  {...text}
                >
                  <tspan {...tSpan}>{ucFirstLetters(category)}</tspan>
                </text>
              </g>
            </Tooltip>
          );
        })}
        <g id="carbonFootprint-primaryLobe">
          <ellipse
            id="carbonFootprint-primaryOval"
            stroke="none"
            fill={
              footprintIsGreaterThanComparison ? darkSkinTone : lightSkinTone
            }
            cx="108.17"
            cy="82.97"
            rx="108.17"
            ry="82.97"
            transform="translate(68.17, 74.43) rotate(6.79)"
          />
          <g id="carbonFootprint-primaryText">
            <text
              fill="rgb(0, 0, 0)"
              fillOpacity="0.54"
              fontFamily="Poppins, HelveticaNeue, 'Helvetica Neue', Helvetica, Arial, sans-serif"
              fontSize="22"
              x="141.28"
              y="116"
              textAnchor="middle"
            >
              <tspan x="164.5" y="135">
                Total
              </tspan>
            </text>
            <text
              fill="rgb(0, 0, 0)"
              fillOpacity="0.87"
              fontFamily="Poppins, HelveticaNeue-Bold, 'Helvetica Neue', Helvetica, Arial, sans-serif"
              fontWeight="bold"
              fontSize="49"
              x="116.96"
              y="143"
              textAnchor="middle"
            >
              <tspan x="164.5" y="187">
                {formatDecimal(tonsToLbs(totalFootprintTons))}
              </tspan>
            </text>
            <text
              fill="rgb(0, 0, 0)"
              fillOpacity="0.54"
              fontFamily="Poppins, HelveticaNeue, 'Helvetica Neue', Helvetica, Arial, sans-serif"
              fontSize="22"
              x="110.09"
              y="195"
              textAnchor="middle"
            >
              <tspan x="164.5" y="214">
                Lbs CO2e
              </tspan>
            </text>
          </g>
        </g>
        <g id="carbonFootprint-comparisonLobe">
          <ellipse
            id="carbonFootprint-comparisonOval"
            stroke="none"
            fill={
              footprintIsGreaterThanComparison ? lightSkinTone : darkSkinTone
            }
            cx="81.45"
            cy="115.46"
            rx="81.45"
            ry="115.46"
            transform="translate(92.58, 259.06) rotate(7.74)"
          />
          <g id="carbonFootprint-comparisonText">
            <text
              fill="rgb(0, 0, 0)"
              fillOpacity="0.54"
              fontFamily="Poppins, HelveticaNeue, 'Helvetica Neue', Helvetica, Arial, sans-serif"
              fontSize="22"
              x="120.63"
              y="295"
              textAnchor="middle"
            >
              <tspan x="157.5" y="326">
                US Avg{" "}
              </tspan>
              <tspan x="157.5" y="352.62">
                Comparison
              </tspan>
            </text>
            <text
              fill="rgb(0, 0, 0)"
              fillOpacity="0.87"
              fontFamily="Poppins, HelveticaNeue-Bold, 'Helvetica Neue', Helvetica, Arial, sans-serif"
              fontWeight="bold"
              fontSize="35"
              x="125.81"
              y="369"
              textAnchor="middle"
            >
              <tspan x="157.5" y="400">
                {formatDecimal(
                  tonsToLbs(
                    isThereMoreThanOnePerson
                      ? US_AVERAGE_COMPARISON * numPeople
                      : US_AVERAGE_COMPARISON
                  )
                )}
              </tspan>
            </text>
            <text
              fill="rgb(0, 0, 0)"
              fillOpacity="0.54"
              fontFamily="Poppins, HelveticaNeue, 'Helvetica Neue', Helvetica, Arial, sans-serif"
              fontSize="22"
              x="103.09"
              y="420"
              textAnchor="middle"
            >
              <tspan x="157.5" y="439">
                Lbs CO2e
              </tspan>
            </text>
          </g>
        </g>
      </svg>
    </>
  );
};
export default CarbonFoot;
