import React from "react";

import { Box } from "@mui/material";

import CarbonFoot from "./CarbonFoot";

const FootprintDisplay = ({
  yearlyTonsCo2eObj,
  activeFormStep,
  individual,
}) => {
  const {
    yearlyHomeTonsCo2e,
    yearlyTravelTonsCo2e,
    yearlyFlightsTonsCo2e,
    yearlyDietTonsCo2e,
    yearlySpendingTonsCo2e,
    yearlyTotalTonsCo2e,
  } = yearlyTonsCo2eObj;

  const { numPeople } = individual || {};

  const footprintDetails = [
    {
      category: "home",
      tonsCo2e: yearlyHomeTonsCo2e,
    },
    { category: "travel", tonsCo2e: yearlyTravelTonsCo2e },
    {
      category: "flights",
      tonsCo2e: yearlyFlightsTonsCo2e,
    },
    {
      category: "diet",
      tonsCo2e: yearlyDietTonsCo2e,
    },
    {
      category: "costs",
      tonsCo2e: yearlySpendingTonsCo2e,
    },
  ];

  const fullFootprintDetails = footprintDetails.map((details, idx) => ({
    ...details,
    isComplete: idx + 1 <= activeFormStep,
    isCurrentStep: idx + 1 === activeFormStep,
  }));

  return (
    <Box
      position="fixed"
      top="104px"
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      p={2}
    >
      <CarbonFoot
        totalFootprintTons={yearlyTotalTonsCo2e}
        footprintDetails={fullFootprintDetails}
        isDesktop
        disableToeTonsCo2eSorting
        customSvgProps={{ height: "600px" }}
        numPeople={numPeople}
      />
    </Box>
  );
};
export default FootprintDisplay;
