import React, { useState, useEffect } from "react";

import OffsetDetailsPurchaseSlider from "../modules/projects/OffsetDetailsPurchaseSlider";

const SubscriptionDetailsSlider = ({
  selectedOffsetBucket,
  setSelectedOffsetBucket,
  startCarouselCycle,
  SubscriptionPurchaseFlow,
  calculatedSubscriptionCost,
}) => {
  const { category_allocations: categoryAllocations = [] } =
    selectedOffsetBucket || {};

  const allProjects = categoryAllocations
    .map((allocation) => allocation.category.projects)
    .flat();

  const isThereMoreThanThreeProjects = allProjects.length > 3;

  const [displayProject, setDisplayProject] = useState(null);

  const offsetsToDisplay = isThereMoreThanThreeProjects
    ? allProjects.slice(0, 3)
    : allProjects;

  useEffect(() => {
    if (offsetsToDisplay.length > 1 && !displayProject) {
      setDisplayProject(offsetsToDisplay[0]);
    }
  }, [displayProject, offsetsToDisplay]);

  return displayProject ? (
    <OffsetDetailsPurchaseSlider
      isCNaughtPurchase
      project={displayProject}
      onSlideClose={() => {
        setSelectedOffsetBucket(null);

        if (startCarouselCycle) {
          return startCarouselCycle();
        }

        return null;
      }}
      ProjectPurchaseFlow={() => (
        <SubscriptionPurchaseFlow
          selectedOffsetBucket={selectedOffsetBucket}
          offsetsToDisplay={offsetsToDisplay}
          setDisplayProject={setDisplayProject}
          calculatedSubscriptionCost={calculatedSubscriptionCost}
        />
      )}
    />
  ) : (
    <></>
  );
};
export default SubscriptionDetailsSlider;
