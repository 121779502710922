import React, { useState } from "react";

import { editObjectData } from "@aclymatepackages/array-immutability-helpers";

import TransactionDetailsBlock from "./TransactionDetailsBlock";

import { useOnVendorSave } from "../../../helpers/components/vendors";
import useTransactionsContext from "../../../helpers/contexts/transactions";

const EditRowTransactions = ({
  transaction,
  setIsSlideOpen,
  ...otherProps
}) => {
  const { id } = transaction;

  const { onUpdateTransaction, onSetTransaction } = useTransactionsContext();
  const { onVendorSave } = useOnVendorSave();

  const [editableTransaction, setEditableTransaction] = useState(transaction);

  const editTransaction = (field) => (value) =>
    editObjectData(setEditableTransaction, field, value);

  const onRejectTransaction = () => {
    setIsSlideOpen(null);
    return onUpdateTransaction(id, { archived: true });
  };

  const onSaveTransaction = async (updateObj) => {
    const {
      knownVendor,
      subcategory,
      naicsCode,
      naicsTitle,
      tonsCo2ePerDollar,
      scopeThreeCategory,
    } = updateObj;

    if (knownVendor) {
      onVendorSave({
        ...knownVendor,
        emissionCategory: subcategory,
        naicsCode,
        naicsTitle,
        tonsCo2ePerDollar,
        scopeThreeCategory,
      });
    }

    return await onSetTransaction(id, updateObj);
  };

  return (
    <TransactionDetailsBlock
      originalTransaction={transaction}
      transaction={editableTransaction}
      editTransaction={editTransaction}
      setIsSlideOpen={setIsSlideOpen}
      onRejectTransaction={onRejectTransaction}
      saveTransaction={onSaveTransaction}
      {...otherProps}
    />
  );
};

export default EditRowTransactions;
