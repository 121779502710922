import React, { useState, useRef, useEffect } from "react";

import {
  Grid,
  Typography,
  ButtonBase,
  Box,
  Avatar,
  Paper,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { hexToRgba } from "@aclymatepackages/converters";
import { mergeMainTheme, mainTheme } from "@aclymatepackages/themes";

const OptionCard = ({
  option,
  chooseOption,
  isSelected,
  color = mainTheme.palette.secondary.main,
  setButtonWidths,
  maxButtonWidth,
  themeColor,
  centeredText = false,
}) => {
  const { title, subtitle, icon, fontAwesomeIcon, value, onClick } = option;

  const theme = useTheme();
  const buttonRef = useRef();

  useEffect(() => {
    if (buttonRef.current) {
      const buttonWidth = buttonRef.current.offsetWidth;

      setButtonWidths((prevState) => [...prevState, buttonWidth]);
    }
  }, [buttonRef, setButtonWidths]);

  const borderStyleObj = isSelected
    ? { border: `4px solid ${color}` }
    : { border: `4px solid transparent` };

  const buttonBackgroundColor =
    themeColor === "dark"
      ? hexToRgba(theme.palette.backgroundGray.dark, 0.8)
      : "rgba(255, 255, 255, 0.8)";

  return (
    <ButtonBase
      ref={buttonRef}
      onClick={() => (onClick ? onClick() : chooseOption(value))}
      style={{
        height: "100%",
        minWidth: `${maxButtonWidth}px`,
      }}
    >
      <ThemeProvider theme={mergeMainTheme}>
        <Paper
          style={{
            width: "100%",
            boxShadow: "none",
            background: buttonBackgroundColor,
          }}
        >
          <Box
            style={{
              height: "100%",
              position: "relative",
              ...borderStyleObj,
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(2),
              padding: "8px",
              borderRadius: "17px",
              justifyContent: centeredText ? "center" : "flex-start",
            }}
          >
            {isSelected && (
              <Box
                style={{
                  backgroundColor: color,
                  position: "absolute",
                  top: "-12px",
                  right: "-12px",
                  padding: `${theme.spacing(1)}px`,
                  color: "white",
                  borderRadius: "50%",
                  height: "24px",
                  width: "24px",
                }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <DoneIcon fontSize="small" />
              </Box>
            )}
            {(icon || fontAwesomeIcon) && (
              <Avatar
                style={{
                  backgroundColor: isSelected
                    ? color
                    : theme.palette.backgroundGray.dark,
                }}
              >
                {fontAwesomeIcon && (
                  <FontAwesomeIcon
                    icon={fontAwesomeIcon}
                    style={{ color: "white" }}
                    size="1x"
                  />
                )}
                {icon}
              </Avatar>
            )}
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: centeredText ? "center" : "flex-start",
                alignItems: centeredText ? "center" : "flex-start",
              }}
            >
              <Typography variant="body1" color="textPrimary">
                {title}
              </Typography>
              {subtitle && (
                <Typography
                  variant="caption"
                  color="textSecondary"
                  display="block"
                >
                  {subtitle}
                </Typography>
              )}
            </Box>
          </Box>
        </Paper>
      </ThemeProvider>
    </ButtonBase>
  );
};

const ButtonCards = ({
  question,
  options,
  setOption,
  selectedOption = [],
  color,
  gridItemProps,
  backgroundColor,
  exclusive = true,
  themeColor,
  centeredText = false,
}) => {
  const [buttonWidths, setButtonWidths] = useState([]);
  const [maxButtonWidth, setMaxButtonWidth] = useState(0);

  useEffect(() => {
    if (!!buttonWidths.length) {
      const max = buttonWidths?.reduce((prev, curr) =>
        curr > prev ? curr : prev
      );
      setMaxButtonWidth(max);
    }
  }, [buttonWidths]);

  const isSelected = (optionValue) => {
    if (!exclusive) {
      return selectedOption.includes(optionValue);
    }

    return optionValue === selectedOption;
  };

  const chooseOption = (isSelected) => (newValue) => {
    if (!exclusive && isSelected) {
      return setOption(selectedOption.filter((value) => value !== newValue));
    }

    if (!exclusive && !isSelected) {
      return setOption([...selectedOption, newValue]);
    }

    if (isSelected) {
      return setOption("");
    }

    return setOption(newValue);
  };

  return (
    <Grid container direction="column" spacing={2}>
      {question && (
        <Grid item>
          <Typography variant="h6" align="center" color="textPrimary">
            {question}
          </Typography>
        </Grid>
      )}
      <Grid item container spacing={2} justifyContent="center">
        {options.map((option, idx) => (
          <Grid key={`input-option-${idx}`} item {...gridItemProps}>
            <OptionCard
              color={color}
              chooseOption={chooseOption(isSelected(option.value))}
              option={option}
              isSelected={isSelected(option.value)}
              backgroundColor={backgroundColor}
              setButtonWidths={setButtonWidths}
              maxButtonWidth={maxButtonWidth}
              themeColor={themeColor}
              centeredText={centeredText}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
export default ButtonCards;
