import React, { useState } from "react";
import dayjs from "dayjs";

import { Grid, Button, CircularProgress, Typography } from "@mui/material";
import ReceiptIcon from "@mui/icons-material/Receipt";

import { formatDate } from "@aclymatepackages/formatters";

import Card from "../../atoms/mui/Card";

import { useQuickbooks } from "../../../helpers/integrations/quickbooks";

const QuickbooksCard = ({ gridProps = { sm: 6 } }) => {
  const [quickbooksError, setQuickbooksError] = useState("");
  const {
    pullDate,
    pullStatus,
    refreshTokenExpiration,
    tokenLoading,
    launchQuickbooks,
    fetchQuickbooksData,
  } = useQuickbooks({ setQuickbooksError });

  const isRefreshTokenExpired = dayjs().isAfter(dayjs(refreshTokenExpiration));
  const isPulling = pullStatus === "running";
  const isPullingError = pullStatus === "error";

  const quickbooksSubtitle = () => {
    if (isPulling) {
      return "Your Quickbooks data is loading. Depending on how much data you have, this could take a few minutes.";
    }
    if (!pullDate) {
      return "We can connect to your Quickbooks account for faster setup";
    }
    if (isRefreshTokenExpired) {
      return "Your Quickbooks login has expired. Reconnect it to resume data syncs from your account.";
    }
    return `You last pulled data from Quickbooks on: ${formatDate(
      pullDate
    )}. Data from synced Quickbooks accounts is automatically pulled at the end of each month.`;
  };

  return (
    <Card
      gridProps={gridProps}
      isLoading={tokenLoading}
      cardType="layout"
      title="Quickbooks"
      subtitle={quickbooksSubtitle()}
      icon={<ReceiptIcon />}
      content={
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item>
            {isPulling ? (
              <CircularProgress />
            ) : !pullDate || isRefreshTokenExpired ? (
              <Button onClick={() => launchQuickbooks()}>
                <img src="/images/quickbooksButton.png" alt="Quickbooks Icon" />
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => fetchQuickbooksData()}
              >
                Fetch Latest Data
              </Button>
            )}
          </Grid>
          {(quickbooksError || isPullingError) && (
            <Grid item>
              <Typography variant="body2" align="center" color="error">
                {isPullingError
                  ? "There was an error pulling your Quickbooks data. Contact support for more details."
                  : quickbooksError}
              </Typography>
            </Grid>
          )}
        </Grid>
      }
    />
  );
};

export default QuickbooksCard;
