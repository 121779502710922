import React from "react";

import { Grid, Typography } from "@mui/material";

import { numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { TextField, ToggleButtons } from "@aclymatepackages/atoms";

import GasUnitSelect from "./GasUnitSelect";

const generateTextFieldLabel = (fuelType) => {
  const labels = {
    naturalGas: "Enter a volume of natural gas",
    propane: "Enter gallons of propane",
    wood: "Enter cords of wood",
    heatingOil: "Enter gallons of heating oil",
  };

  return labels[fuelType];
};

const GasInputForm = ({
  fuelType,
  setFuelType,
  unitValue,
  setUnitValue,
  gasUnit,
  setGasUnit,
}) => (
  <Grid container spacing={2} direction="column">
    <Grid item>
      <Typography variant="h6">Gas Utility</Typography>
    </Grid>
    <Grid item>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Typography variant="body1">Fuel Type: </Typography>
        </Grid>
        <Grid item>
          <ToggleButtons
            buttons={[
              { name: "Natural Gas", value: "naturalGas" },
              { name: "Propane", value: "propane" },
              { name: "Wood", value: "wood" },
              { name: "Heating Oil", value: "heatingOil" },
            ]}
            value={fuelType}
            onChange={(value) => setFuelType(value)}
            size="small"
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item container spacing={2}>
      <Grid item xs={fuelType === "naturalGas" ? 8 : 12}>
        <TextField
          fullWidth
          variant="outlined"
          label={generateTextFieldLabel(fuelType)}
          value={unitValue}
          setValue={(value) => setUnitValue(value)}
          helperText={
            unitValue &&
            (!numbersRegExpTest(unitValue) || unitValue >= 3000000) &&
            "This must be a number and be less than 3,000,000"
          }
          id="gas-utility-input"
        />
      </Grid>
      {fuelType === "naturalGas" && (
        <Grid item xs={4}>
          <GasUnitSelect
            gasUnit={gasUnit}
            editGasUnit={(value) => setGasUnit(value)}
          />
        </Grid>
      )}
    </Grid>
  </Grid>
);
export default GasInputForm;
