import { useState, useRef, useEffect, useContext } from "react";
import dayjs from "dayjs";

import { PlatformLayoutContext } from "../contexts/platformLayout";
import { fetchOurApi } from "../utils/apiCalls";
import { useCollectionDataListener, useCachedFirebaseCrud } from "../firebase";
import { analyticsTrack } from "../analytics";
import { getAccountCollectionAndId } from "../otherHelpers";

const requestURI = async () => {
  const { id: companyId } = getAccountCollectionAndId();
  return await fetchOurApi({
    path: `/oauth/quickbooks/requestURI/${companyId}`,
    method: "GET",
    callback: (URI) => URI,
  });
};

export const checkToken = async () => {
  const { id: companyId } = getAccountCollectionAndId();
  return await fetchOurApi({
    path: `/oauth/quickbooks/check/${companyId}`,
    method: "GET",
    callback: (check) => check,
  });
};

export const useQuickbooks = (props = {}) => {
  const { setOpen, setQuickbooksError } = props;
  const [[quickbooksToken = {}], tokenLoading] = useCollectionDataListener(
    "integrations",
    [["name", "==", "quickbooks"]]
  );
  const { deleteCachedCollectionDoc } = useCachedFirebaseCrud();
  const {
    pullDate,
    pullStatus,
    id: tokenDocId,
    refreshTokenExpiration,
  } = quickbooksToken;

  const { activateSnackbar } = useContext(PlatformLayoutContext) || {};
  const windowRef = useRef();
  const [isWindowOpen, setIsWindowOpen] = useState(false);

  const launchQuickbooks = async () => {
    analyticsTrack("Quickbooks Synced");
    const URI = await requestURI();
    windowRef.current = window.open(URI, "quickbooks Popup", "toolbars=0");
    if (!windowRef.current && setQuickbooksError) {
      return setQuickbooksError(
        "This popup was blocked by your browser. Please allow popups to connect to QuickBooks."
      );
    }
    return setIsWindowOpen(true);
  };

  const fetchQuickbooksData = async () => {
    const check = await checkToken();
    const { URI, isPopup } = check;
    if (isPopup) {
      windowRef.current = window.open(URI, "quickbooks Popup", "toolbars=0");
      return setIsWindowOpen(true);
    }
    return;
  };

  const signOutQuickbooks = async () =>
    deleteCachedCollectionDoc("integrations", tokenDocId);

  const lastPull = pullDate && dayjs(pullDate);
  const difference = dayjs().diff(lastPull, "second");

  useEffect(() => {
    const loadingMessage = () => {
      if (
        windowRef.current &&
        difference &&
        difference < 10 &&
        activateSnackbar
      ) {
        activateSnackbar({
          message:
            "Your transactions are being loaded from QuickBooks. You'll get an email when we're done.",
          alert: "success",
        });
        if (setOpen) {
          setOpen(false);
        }
        return setIsWindowOpen(false);
      }
    };
    if (isWindowOpen) {
      loadingMessage();
    }
  }, [difference, isWindowOpen, setOpen, activateSnackbar]);

  return {
    pullDate,
    pullStatus,
    refreshTokenExpiration,
    tokenLoading,
    launchQuickbooks,
    fetchQuickbooksData,
    signOutQuickbooks,
  };
};
