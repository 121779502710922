import { createContext, useContext } from "react";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import { buildCompanyEmissionsArrays } from "@aclymatepackages/calcs/recurring";

import { useEmployeeStatuses } from "../components/employees";
import { PlatformLayoutContext } from "./platformLayout";
import { SandboxDataContext } from "./sandboxData";
import { AdminDataContext } from "./adminData";
import useTransactionsContext from "./transactions";

import { useCachedDisplayData, useAccountData } from "../firebase";

import { useDisplayEvents } from "../components/events";
import findSubcategory from "@aclymatepackages/subcategories";
import { convertVolumeUnitsToTons } from "../otherHelpers";

dayjs.extend(isSameOrAfter);

const EmissionsContext = createContext();

const scopeToSubcategory = {
  1: "gas",
  2: "electricity",
  3: "spend-based",
};

export const EmissionsContextProvider = ({ children }) => {
  const { viewMode = "company" } = useContext(PlatformLayoutContext) || {};
  const { sandboxData } = useContext(SandboxDataContext) || {};
  const { adminData } = useContext(AdminDataContext) || {};
  const { companyData: sandboxCompanyData } = sandboxData || {};
  const { companyData: adminCompanyData } = adminData || {};
  const { startDate: sandboxStartDate } = sandboxCompanyData || {};
  const { startDate: adminStartDate } = adminCompanyData || {};

  const [accountData, accountDataLoading] = useAccountData();
  const { startDate: companyStartDate } = accountData;

  const { transactionsObj, transactionsLoading } = useTransactionsContext();
  const [dbEmissions, dbEmissionsLoading] = useCachedDisplayData("emissions");
  const [employees, employeesLoading] = useEmployeeStatuses();
  const [offices, officesLoading] = useCachedDisplayData("offices");
  const [events, eventsLoading] = useDisplayEvents();

  const externalDataLoading =
    employeesLoading ||
    officesLoading ||
    accountDataLoading ||
    transactionsLoading ||
    eventsLoading ||
    dbEmissionsLoading;

  const emissionStartDates = {
    admin: adminStartDate,
    sandbox: sandboxStartDate,
    company: companyStartDate,
  };

  const emissionsStartDate = emissionStartDates[viewMode];

  const buildEmissionsArraysFromExternalData = () => {
    if (externalDataLoading) {
      return {};
    }

    const { allEmissions, ...otherEmissionsArrays } =
      buildCompanyEmissionsArrays({
        companyData: {
          ...accountData,
          startDate: emissionsStartDate,
        },
        dbEmissions,
        employees,
        offices,
        transactionsObj,
        events,
      });

    const dbEmissionsAsTransactions = dbEmissions.length
      ? dbEmissions.map(
          ({
            name,
            startDate,
            date,
            scope,
            tonsCo2e,
            id,
            volume,
            volumeUnit,
            subcategory,
          }) => {
            const emissionVolumeProps = volume ? { volume, volumeUnit } : {};

            return {
              id,
              date: startDate || date,
              source: "manual-emission",
              vendor: name,
              name,
              currentStatus: "confirmed",
              tonsCo2e:
                tonsCo2e || convertVolumeUnitsToTons(volume, volumeUnit),
              subcategory: subcategory || scopeToSubcategory[scope],
              scope: scope || findSubcategory(subcategory)?.scope,
              ...emissionVolumeProps,
            };
          }
        )
      : [];

    return {
      ...otherEmissionsArrays,
      allEmissions: [...allEmissions, ...dbEmissionsAsTransactions],
    };
  };

  const {
    emissionsMonths = [],
    monthlyCommuteEmissionsTons = [],
    monthlyUtilitiesEmissionsTons = [],
    totalRecurringEmissionsTons = [],
    averageMonthlyRecurringEmissionsTons = [],
    separateUtilitiesOfficesDefaultEmissions = [],
    allEmissions = [],
  } = buildEmissionsArraysFromExternalData();

  return (
    <EmissionsContext.Provider
      value={{
        emissionsMonths,
        monthlyCommuteEmissionsTons,
        monthlyUtilitiesEmissionsTons,
        totalRecurringEmissionsTons,
        averageMonthlyRecurringEmissionsTons,
        recurringEmissionsLoading: externalDataLoading,
        separateUtilitiesOfficesDefaultEmissions,
        allEmissions,
      }}
    >
      {children}
    </EmissionsContext.Provider>
  );
};

const useEmissionsContext = () => useContext(EmissionsContext);
export default useEmissionsContext;
