import React from "react";

import { Grid, Typography } from "@mui/material";

import { CategoriesAvatar } from "@aclymatepackages/atoms";
import { ucFirstLetters } from "@aclymatepackages/formatters";

const CustomTooltipDisplayRow = ({
  category,
  customAvatar,
  color,
  name,
  subtitle,
  viewMode,
  subcategory,
  scope,
}) => (
  <Grid item container spacing={1} wrap="nowrap" alignItems="center">
    <Grid item>
      {customAvatar || (
        <CategoriesAvatar
          viewMode={viewMode}
          color={color}
          subcategory={subcategory}
          scope={scope}
        />
      )}
    </Grid>
    <Grid item container direction="column" wrap="nowrap">
      <Grid item xs={12}>
        <Typography
          variant="subtitle1"
          color="textPrimary"
          noWrap
        >{`${ucFirstLetters(name || category)}`}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption" color="textSecondary">
          {subtitle}
        </Typography>
      </Grid>
    </Grid>
  </Grid>
);
export default CustomTooltipDisplayRow;
