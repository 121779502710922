import React from "react";

import { InputAdornment, IconButton, CircularProgress } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { TextField } from "@aclymatepackages/atoms";

const SearchActionInput = ({
  value,
  setValue,
  label,
  searchFunction,
  searchLoading,
  ...otherProps
}) => {
  const onEnterPress = ({ key }) => {
    if (key === "Enter" && value) {
      return searchFunction();
    }
  };

  return (
    <TextField
      label={label}
      onKeyUp={onEnterPress}
      value={value}
      setValue={setValue}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {searchLoading ? (
              <CircularProgress />
            ) : (
              <IconButton
                onClick={searchFunction}
                size="large"
                disabled={!value}
              >
                <SearchIcon />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
      {...otherProps}
    />
  );
};
export default SearchActionInput;
