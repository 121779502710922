import { Button } from "@mui/material";

import { letterSBoolean } from "@aclymatepackages/formatters";

import { scopeThreeSpendBasedCategories } from "./vendors";

import useDashboardDataContext from "../contexts/dashboardData";
import useTransactionsContext from "../contexts/transactions";

export const usePrimaryViewAlerts = (setShowDuplicateTransactions) => {
  const { transactionsObj } = useTransactionsContext();
  const { duplicateTransactionsGroups = [] } = useDashboardDataContext();

  const { unconfirmedTransactions = [] } = transactionsObj;
  const unconfirmedTransactionsAlert = unconfirmedTransactions.length
    ? [
        {
          title: `You have ${
            unconfirmedTransactions.length
          } transaction${letterSBoolean(
            unconfirmedTransactions
          )} that need more information.`,
          subtitle:
            "These transactions have been added to your balance, but we need more information to increase their accuracy. Scroll down to the transactions table to view and edit the transactions that need more data input from you.",
        },
      ]
    : [];

  const duplicateTransactionsAlert = duplicateTransactionsGroups.length
    ? [
        {
          title: `You have ${
            duplicateTransactionsGroups.length
          } group${letterSBoolean(
            duplicateTransactionsGroups
          )} of possibly duplicate transactions.`,
          subtitle: "Click to review and clean up these transactions",
          action: (
            <Button
              color="inherit"
              onClick={() => setShowDuplicateTransactions(true)}
            >
              View Duplicates
            </Button>
          ),
        },
      ]
    : [];

  return [...unconfirmedTransactionsAlert, ...duplicateTransactionsAlert];
};

export const extractScopeThreeEmissions = (emissions) =>
  emissions.map((emission = {}) => {
    const { subcategory, scopeThreeCategory } = emission;
    if (subcategory !== "spend-based" || scopeThreeCategory < 0) {
      return emission;
    }

    const { subcategory: scopeThreeSubcategory } =
      scopeThreeSpendBasedCategories.find(
        ({ value }) => value === scopeThreeCategory
      ) || {};

    if (!scopeThreeSubcategory) {
      return emission;
    }

    return { ...emission, subcategory: scopeThreeSubcategory };
  });
