import { useState, useContext } from "react";
import dayjs from "dayjs";
import { Promise as bluebird } from "bluebird";
import { useSWRConfig } from "swr";

import useCsvUploader from "./csvUploader";
import { PlatformLayoutContext } from "../../helpers/contexts/platformLayout";

import {
  createErrorObj,
  doesFieldExist,
  useSharedFormLoading,
} from "../../helpers/components/inputs";

import { analyticsTrack } from "../../helpers/analytics";
import { letterSBoolean } from "@aclymatedev/formatters";
import { useAccountData } from "../../helpers/firebase";
import { fetchOurApi } from "../../helpers/utils/apiCalls";
import { editObjectData } from "@aclymatepackages/array-immutability-helpers";

const fieldOptions = [
  { label: "Date (MM/DD/YYYY)", value: "dateCommon" },
  { label: "Unit (kwh)", value: "unitKwh" },
  { label: "Unit (gallons-propane)", value: "unitGallonsPropane" },
  { label: "Unit (therms)", value: "unitTherms" },
  { label: "Unit (cords)", value: "unitCords" },
  { label: "Unit (gallons-heating-oil)", value: "unitGallonsHeatingOil" },
];

const requiredFields = ["Date", "Unit"];

const knownFields = [
  { header: "dateCommon", field: "dateCommon" },
  { header: "unitKwh", field: "unitKwh" },
  { header: "unitGallonsPropane", field: "unitGallonsPropane" },
  { header: "unitTherms", field: "unitTherms" },
  { header: "unitCords", field: "unitCords" },
  { header: "unitGallonsHeatingOil", field: "unitGallonsHeatingOil" },
];

const fieldValidator = (headers) => {
  if (
    !doesFieldExist(headers, "dateCommon") &&
    !doesFieldExist(headers, "dateMMMMYY")
  ) {
    return createErrorObj("Your CSV must include a Date field");
  }
  if (
    !doesFieldExist(headers, "unitKwh") &&
    !doesFieldExist(headers, "unitGallonsPropane") &&
    !doesFieldExist(headers, "unitTherms") &&
    !doesFieldExist(headers, "unitCords") &&
    !doesFieldExist(headers, "unitGallonsHeatingOil")
  ) {
    return createErrorObj("Your CSV must include a Unit field");
  }
  return { success: true };
};

const useProcessUtilities = ({
  fileInfo,
  setFileInfo,
  setUtilitiesResponseCount,
  setTotalUtilitiesCount,
  setOpen,
}) => {
  const accountId = window.sessionStorage.getItem("accountId");
  const { csvId, name: fileName } = fileInfo;
  const { setFormLoading } = useSharedFormLoading();
  const [{ startDate, geography }] = useAccountData();

  const { address } = geography || {};
  const { zipCode } = address || {};

  const { mutate } = useSWRConfig();

  const { activateSnackbar } = useContext(PlatformLayoutContext);

  const sendUtilitiesToBackend = async (utility) => {
    return await fetchOurApi({
      path: "/transactions/utility-sort",
      method: "POST",
      data: {
        utility,
        companyId: accountId,
        zipCode,
      },
      callback: (response) => {
        setUtilitiesResponseCount((currentCount) => currentCount + 1);
        const { success, utility: responseUtility } = response;
        if (success) {
          const dummyId = Math.random() * Date.now();
          return { ...responseUtility, id: dummyId };
        }
        if (!success) {
          console.log("no success");
          return null;
        }
      },
    });
  };

  const sortOrExcludeUtilities = async (utility) => {
    const { date } = utility;

    if (startDate && dayjs(date).isBefore(startDate)) {
      setUtilitiesResponseCount((currentCount) => currentCount + 1);
      return { ...utility, beforeStartDate: true };
    }

    return sendUtilitiesToBackend(utility);
  };

  return async ({ data: utilities }) => {
    setFormLoading(true);

    editObjectData(setFileInfo, "csvId", csvId);

    analyticsTrack("Utilities Loaded", {
      numberOfUtilities: utilities.length,
    });

    setTotalUtilitiesCount(utilities.length);

    const utilitiesAdditionalData = utilities.map((utility) => ({
      ...utility,
      source: "csv-upload",
      csvId,
      csvName: fileName,
      status: "confirmed",
      vendor: `${dayjs().format("MM/DD/YYYY")} Utilities Emissions`,
      name: `${dayjs().format("MM/DD/YYYY")} Utilities Emissions`,
      emissionProbability: "high",
    }));

    const newUtilities = await bluebird.map(
      utilitiesAdditionalData,
      sortOrExcludeUtilities,
      { concurrency: 10 }
    );

    const storedUtilities = newUtilities.filter(
      (utility) => utility && !utility?.duplicate && !utility?.beforeStartDate
    );

    mutate("transactions", (existingTransactions) => [
      ...existingTransactions,
      ...storedUtilities,
    ]);

    const snackbarMessage = `${
      newUtilities.length
    } utility emission${letterSBoolean(
      newUtilities
    )} were added to your account`;
    activateSnackbar({ message: snackbarMessage, alert: "success" });

    setOpen(false);
    return setFormLoading(false);
  };
};

const useUtilitiesCsvUploader = ({ open, setOpen }) => {
  const [fileInfo, setFileInfo] = useState({});
  const [matchedHeaders, setMatchedHeaders] = useState([]);
  const [totalUtilitiesCount, setTotalUtilitiesCount] = useState(0);
  const [utilitiesResponseCounts, setUtilitiesResponseCount] = useState(0);

  const processData = useProcessUtilities({
    fileInfo,
    setFileInfo,
    setUtilitiesResponseCount,
    setTotalUtilitiesCount,
    setOpen,
  });

  const csvUploaderSteps = useCsvUploader({
    knownFields,
    fieldOptions,
    open,
    setOpen,
    fileInfo,
    setFileInfo,
    matchedHeaders,
    setMatchedHeaders,
    processData,
    fieldValidator,
    docType: "utilities",
    firstStepText:
      "Upload a CSV of your transactions and we'll automatically sort out the ones that you can offset.",
    requiredFields,
  });

  return [
    csvUploaderSteps,
    (utilitiesResponseCounts / totalUtilitiesCount) * 100,
  ];
};
export default useUtilitiesCsvUploader;
