import React from "react";

import { Box, Grid, CircularProgress, Typography } from "@mui/material";

import PurchaseDialogLayout from "../layouts/PurchaseDialogLayout";
import StripeElements from "../modules/StripeElements";
import useStripeInput from "../hooks/stripeInput";

import { useCreateStripeSubscription } from "../../helpers/hooks/stripe";
import { analyticsTrack } from "../../helpers/analytics";

const PaymentInfoBox = ({
  selectedOffsetBucket,
  onSavePaymentInfoButtonClick,
}) => {
  const { name: title } = selectedOffsetBucket || {};

  const { input, button } = useStripeInput({
    onButtonClick: onSavePaymentInfoButtonClick,
    buttonText: "Subscribe",
  });

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item style={{ width: "100%" }}>
          {input}
        </Grid>
        <Grid item container justifyContent="center">
          {button}
        </Grid>
        <Grid item>
          <Typography
            color="textSecondary"
            align="center"
            variant="caption"
            component="div"
          >
            {`By clicking this button you agree to immediately create a subscription for our ${title} plan. You will be automatically billed on the interval that you've selected until you cancel. Your payment method will immediately be billed the amount shown above.`}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const PurchaseFlowPaymentInfoBox = ({
  individual,
  dialogSubtitle = "We need your credit card or US bank account information before you can start buying offsets",
  selectedOffsetBucket,
  subscriptionTitle,
}) => {
  const { stripeSubmit } = useCreateStripeSubscription();

  const { stripePriceId } = selectedOffsetBucket || {};

  const { email } = individual;

  const onSavePaymentInfoButtonClick = async () => {
    await stripeSubmit({
      subscriptionType: selectedOffsetBucket,
      stripePriceId,
      interval: "month",
      product: selectedOffsetBucket,
    });

    return analyticsTrack(
      "myAclymate Offset Subscription Purchase (Impact Survey)",
      {
        email,
        subscriptionTitle,
      }
    );
  };

  return (
    <PurchaseDialogLayout
      title="Enter your payment information"
      subtitle={dialogSubtitle}
    >
      <StripeElements loadingComponent={<CircularProgress />}>
        <PaymentInfoBox
          selectedOffsetBucket={selectedOffsetBucket}
          onSavePaymentInfoButtonClick={onSavePaymentInfoButtonClick}
        />
      </StripeElements>
    </PurchaseDialogLayout>
  );
};
export default PurchaseFlowPaymentInfoBox;
