import { useState } from "react";
import { useParams } from "react-router";

import {
  faCheck,
  faQuestionCircle,
  faReceipt,
  faListCheck,
} from "@fortawesome/free-solid-svg-icons";
import { faBlockQuestion } from "@fortawesome/pro-solid-svg-icons";

import { findVendorEmissionCategoryData } from "@aclymatepackages/calcs/vendors";
import { letterSBoolean } from "@aclymatepackages/formatters";
import { sumTonsCo2e } from "@aclymatepackages/other-helpers";
import mainTheme from "@aclymatepackages/themes";

import { useAccountData, useCachedFirebaseCrud, useAuth } from "../firebase";
import { fetchOurApi, useApiData } from "../utils/apiCalls";
import useTransactionsContext from "../contexts/transactions";

export const scopeThreeSpendBasedCategories = [
  {
    label: "Purchased Goods and Services",
    value: 1,
    subcategory: "purchased-goods-services",
  },
  {
    label: "Capital Goods",
    value: 2,
    subcategory: "capital-goods",
  },
  {
    label: "Upstream Transportation and Distribution",
    value: 4,
    subcategory: "upstream-transport-distribution",
  },
  {
    label: "Business Travel",
    value: 6,
    subcategory: "business-travel",
  },
  {
    label: "None",
    value: -1,
  },
];

export const vendorStatuses = {
  confirmed: {
    id: "confirmed",
    icon: faCheck,
    severity: 0,
    tooltip: "This vendor is confirmed and has confirmed transactions",
    name: "Confirmed Vendors",
    color: mainTheme.palette.secondary.main,
  },
  uncategorized: {
    id: "uncategorized",
    severity: 2,
    tooltip:
      "This vendor hasn't been categorized so their emissions haven't been added to your balance.",
    name: "Uncategorized Vendors",
    icon: faQuestionCircle,
    color: mainTheme.palette.error.main,
  },
  noTransactions: {
    id: "noTransactions",
    icon: faReceipt,
    tooltip:
      "This vendor has been categorized but doesn't have any transactions",
    name: "No transactions",
    color: mainTheme.palette.error.light,
    severity: 1,
  },
  unconfirmed: {
    id: "unconfirmed",
    icon: faListCheck,
    name: "Unconfirmed",
    color: mainTheme.palette.error.dark,
    severity: 3,
  },
  unmatched: {
    id: "unmatched",
    icon: faBlockQuestion,
    tooltip: "This vendor hasn't been matched yet.",
    name: "Unmatched",
    color: mainTheme.palette.text.secondary,
    severity: 4,
  },
};

export const findVendorStatus = ({ emissionCategory, transactions }) => {
  if (emissionCategory && transactions.length) {
    return vendorStatuses.confirmed;
  }

  if (emissionCategory) {
    return vendorStatuses.noTransactions;
  }

  return vendorStatuses.uncategorized;
};

export const formatVendors = ({
  matchedVendors,
  unmatchedVendors,
  transactionsObj,
}) => {
  //This is the preliminary vendor score V0
  //For now we're doing just a 3 star score, so we want numbers that are easy multiples of 3.
  //But, we're going to store the scoreas a numeric 0-100 scale for future versions where we develope a more robust scoring system
  const findVendorScore = ({
    vendorData = {},
    stripeCustomerId,
    revenueYears = [],
  }) => {
    if (stripeCustomerId && revenueYears.length) {
      return 90;
    }

    const { surveys } = vendorData;
    if (!surveys) {
      return 30;
    }

    const [{ dateCompleted }] = surveys;
    if (!dateCompleted) {
      return 30;
    }

    return 60;
  };

  const findVendorSurveyStatus = ({ vendorData, stripeCustomerId }) => {
    if (stripeCustomerId) {
      return "has-account";
    }

    const { surveys } = vendorData || {};
    if (!vendorData || !surveys) {
      return "no-survey";
    }

    const [{ dateCompleted }] = surveys;
    if (dateCompleted) {
      return "survey-completed";
    }

    return "survey-sent";
  };

  const extractAndSumVendorTransactions = ({ id }) => {
    const vendorTransactions = transactionsObj[`vendor_${id}`] || [];

    const tonsCo2e = sumTonsCo2e(vendorTransactions);

    return {
      transactions: vendorTransactions,
      transactionsCount: vendorTransactions.length,
      tonsCo2e,
      avgTonsCo2ePerTransaction: tonsCo2e / vendorTransactions.length,
    };
  };

  const formatMatchedVendors = (vendor) => {
    const { id, name, vendorData, ...otherProps } = vendor;

    const vendorCategoryProps = findVendorEmissionCategoryData(vendor) || {};

    const vendorTransactionProps = extractAndSumVendorTransactions(vendor);

    return {
      ...otherProps,
      id,
      name,
      ...vendorCategoryProps,
      surveyStatus: findVendorSurveyStatus(vendor),
      vendorScore: findVendorScore(vendor),
      ...vendorData,
      ...vendorTransactionProps,
    };
  };

  const formattedMatchedVendors = matchedVendors.map((vendor) => {
    const formattedVendor = formatMatchedVendors(vendor);

    const { transactions, emissionCategory } = formattedVendor;

    const { id: status, severity } = findVendorStatus({
      emissionCategory,
      transactions,
    });

    return {
      ...formattedVendor,
      status,
      severity,
    };
  });

  const formattedUnmatchedVendors = unmatchedVendors.map((vendor) => {
    const vendorTransactionProps = extractAndSumVendorTransactions(vendor);

    const { id: status, severity } = vendorStatuses.unmatched;

    return {
      ...vendor,
      ...vendorTransactionProps,
      vendorScore: 0,
      status,
      severity,
    };
  });

  return [...formattedMatchedVendors, ...formattedUnmatchedVendors];
};

export const buildVendorAlerts = (displayVendors) => {
  const uncategorizedVendors = displayVendors.filter(
    ({ status }) => status === "uncategorized"
  );
  const noTransactionsVendors = displayVendors.filter(
    ({ status }) => status === "noTransactions"
  );

  const uncategorizedVendorsAlert = uncategorizedVendors.length
    ? [
        {
          title: `You have ${
            uncategorizedVendors.length
          } vendor${letterSBoolean(uncategorizedVendors)} that need${
            uncategorizedVendors.length === 1 ? "s" : ""
          } to be categorized`,
          subtitle:
            "Scroll down to the vendors table to view and edit the vendors that need more data input from you.",
        },
      ]
    : [];

  const noTransactionsAlert = noTransactionsVendors.length
    ? [
        {
          title: `You have ${
            noTransactionsVendors.length
          } vendor${letterSBoolean(
            noTransactionsVendors
          )} that don't have any transactions`,
          subtitle:
            "Scroll down to the vendors table to view and edit the vendors that need more data input from you.",
        },
      ]
    : [];

  return [...uncategorizedVendorsAlert, ...noTransactionsAlert];
};

export const useUpdateVendorTransactions = () => {
  const { onUpdateTransaction } = useTransactionsContext();
  const [{ geography }] = useAccountData();
  const { address: companyAddress } = geography || {};

  return async ({
    transactions = [],
    tonsCo2ePerDollar,
    emissionCategory,
    name: vendorName,
    id: vendorId,
    scopeThreeCategory,
    naicsCode,
    naicsTitle,
  }) => {
    if (!transactions.length) {
      return;
    }

    const findTransactionTonsCo2e = async (transaction) => {
      if (emissionCategory === "spend-based") {
        const { value } = transaction;
        return value * tonsCo2ePerDollar;
      }

      return await fetchOurApi({
        path: "/transactions/estimate-carbon",
        method: "POST",
        data: {
          transaction: { ...transaction, subcategory: emissionCategory },
          companyAddress,
        },
        callback: ({ tonsCo2e }) => tonsCo2e,
      });
    };

    return await Promise.all(
      transactions.map(async (transaction) => {
        const { id, date, name, source, value, rawValue, vendor } = transaction;

        const tonsCo2e = await findTransactionTonsCo2e(transaction);
        const status =
          emissionCategory === "spend-based" ? "confirmed" : "unconfirmed";

        return await onUpdateTransaction(id, {
          archived: false,
          date,
          name,
          source,
          status,
          value,
          rawValue,
          knownVendor: { name: vendorName, id: vendorId },
          tonsCo2e,
          subcategory: emissionCategory,
          naicsTitle,
          naicsCode,
          scopeThreeCategory,
          tonsCo2ePerDollar,
          vendor,
        });
      })
    );
  };
};

export const useOnVendorSave = (callback = () => {}) => {
  const updateVendorTransactions = useUpdateVendorTransactions();
  const { updateCollectionDoc } = useCachedFirebaseCrud();

  const [saveLoading, setSaveLoading] = useState(false);

  const onVendorSave = async (vendor) => {
    const {
      emissionCategory,
      name: vendorName,
      id: vendorId,
      scopeThreeCategory,
      naicsCode,
      naicsTitle,
      status,
    } = vendor;

    const collection = status === "unmatched" ? "vendors" : "vendor-companies";

    const vendorData = {
      emissionCategory,
      scopeThreeCategory,
      naicsTitle,
      naicsCode,
    };

    const vendorPropsUpdateObj =
      status === "unmatched" ? vendorData : { vendorData };

    setSaveLoading(true);
    updateVendorTransactions(vendor);
    updateCollectionDoc(collection, vendorId, {
      name: vendorName,
      ...vendorPropsUpdateObj,
    });

    return callback();
  };

  return { saveLoading, onVendorSave, setSaveLoading };
};

export const useVendorSurveyData = (collection) => {
  const [user, userLoading] = useAuth();
  const { uid: userId } = user || {};
  const { invitationId, companyId } = useParams();

  return useApiData({
    path: "/vendors/fetch-vendor-data",
    method: "POST",
    data: {
      companyId,
      invitationId,
      userId,
      collection,
    },
    isLoading: userLoading,
  });
};
