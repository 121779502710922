import React, { useEffect, useState } from "react";

import { LabelList } from "recharts";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Avatar } from "@mui/material";

//WL 10-20-23: This empty label with a useEffect is a super weird hack, but it's the only way I could figure out how to draw status labels
const WarningLabelSetter = ({ value, y, x, setWarningLabels, id, width }) => {
  useEffect(() => {
    const setWarningLabelValues = () => {
      if (value) {
        const { color, icon } = value;
        return setWarningLabels((existingLabels) => {
          const otherLabels = existingLabels.filter(
            (label) => label?.id !== id
          );
          return [...otherLabels, { id, x, y, color, icon, width }];
        });
      }

      return setWarningLabels((existingLabels) =>
        existingLabels.filter((label) => label?.id !== id)
      );
    };

    setWarningLabelValues();
  }, [value, x, y, id, setWarningLabels, width]);

  return <></>;
};

const WarningLabel = ({ x, y, icon, color, width, value, maxChartValue }) => {
  const AVATAR_WIDTH = 40;

  const buildCommonProps = (topOffset) => ({
    backgroundColor: color,
    position: "absolute",
    top:
      maxChartValue * 0.9 > value ? y - topOffset * 1.25 : y - AVATAR_WIDTH / 2,
  });

  const buildCenteringProps = (warningWidth) => ({
    left: x + width / 2,
    transform: `translateX(-${warningWidth / 2}px)`,
  });

  if (width >= AVATAR_WIDTH) {
    return (
      <Avatar
        style={{
          ...buildCommonProps(AVATAR_WIDTH),
          ...buildCenteringProps(AVATAR_WIDTH),
        }}
      >
        <FontAwesomeIcon icon={icon} style={{ color: "white" }} />
      </Avatar>
    );
  }

  const BADGE_WIDTH = 10;

  const buildBadgeProps = (badgeWidth) => ({
    width: badgeWidth,
    height: badgeWidth,
    borderRadius: "50%",
  });

  if (width >= BADGE_WIDTH) {
    return (
      <div
        style={{
          ...buildCommonProps(BADGE_WIDTH),
          ...buildCenteringProps(BADGE_WIDTH),
          ...buildBadgeProps(BADGE_WIDTH),
        }}
      />
    );
  }

  return (
    <div
      style={{
        ...buildCommonProps(width),
        left: x,
        ...buildBadgeProps(width),
      }}
    />
  );
};

const useChartWarningLabels = ({ data, warningField, barSumField }) => {
  const [warningLabels, setWarningLabels] = useState([]);

  useEffect(() => {
    if (!data.some((dataObj) => dataObj[warningField])) {
      setWarningLabels([]);
    }

    setWarningLabels((currentLabels) =>
      currentLabels.filter(({ id }) =>
        data.find((dataObj) => dataObj.id === id)
      )
    );
  }, [data, warningField]);

  const maxChartValue = Math.max(
    ...data.map((dataObj) => dataObj[barSumField])
  );

  return {
    labelSetter: (
      <>
        <LabelList
          position="insideTopLeft"
          dataKey={warningField}
          content={<WarningLabelSetter setWarningLabels={setWarningLabels} />}
        />
      </>
    ),
    warningLabels: (
      <>
        {!!warningLabels.length &&
          warningLabels.map((warning, idx) => (
            <WarningLabel
              key={`chart-warning-label-${idx}`}
              maxChartValue={maxChartValue}
              value={
                data.find((dataObj) => dataObj.id === warning.id)?.[barSumField]
              }
              {...warning}
            />
          ))}
      </>
    ),
  };
};
export default useChartWarningLabels;
